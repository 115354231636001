import Api from "../../components/Api";
import { BRANDS, SELECTED_BRAND } from "./brandType";

export const brandsList = (requestType?: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/brands', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.forEach((obj: any) => {
                        obj.label = obj.brand_name;
                        obj.value = obj.brand_id;
                    }) 
                    if (requestType == "GET_BY_BRAND") {
                        let newObj = {
                            value: 0,
                            label: "All"
                        }
                        response.data.successResponse.unshift(newObj)
                    }
                    dispatch({
                        type: BRANDS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const saveBrand = (brand_id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
                    dispatch({
                        type: SELECTED_BRAND,
                        payload: brand_id
                    })
    }
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import jwt from 'jsonwebtoken'
import { API_URL } from '../client-config';
export default class Sidebar extends Component<{}, {clicked:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state={
            clicked:window.location.pathname.slice(1)
        }
    }
    handleClick = (value:any)=>{
        this.setState({clicked:value})
    }
    render() {
        let page = window.location.pathname.split('/');
        let name = sessionStorage.name;
        let tokendata: any = jwt.decode(sessionStorage.token)
        let features: any = {};
        if (sessionStorage.token) {
            features = tokendata.features ? JSON.parse(tokendata.features) : tokendata.features;
        }
        return (
            // <!-- Side Navbar -->
            <nav className="side-navbar">
                {/* <!-- Sidebar Header--> */}
                <div className="sidebar-header d-flex align-items-center">
                    <div className="avatar">
                        <img src='https://www.galadarigroup.com/wp-content/themes/Galadari_brothers/images/main-logo.png' className="img-fluid rounded-circle" style={{ marginTop: 5 }}/>
                        {/* <img src={process.env.PUBLIC_URL + "/assets/img/br-logo.png"} alt="..." className="img-fluid rounded-circle" style={{ marginTop: 5 }} /> */}
                        {/* <img src={API_URL +'/'+ tokendata.brand.landing_image} alt="..." className="img-fluid rounded-circle" style={{ marginTop: 5 }} /> */}
                    </div>
                    <div className="title">
                        <h1 className="h4">{name}</h1>
                    </div>
                </div>
                {/* <!-- Sidebar Navidation Menus--> */}
                <ul className="list-unstyled">
                {(features && features.feature20 && features.feature20.permission == 1) && <li onClick={()=>this.handleClick("dashboard")} className={page[1] === 'dashboard' ? 'active' : ''}><Link style={{background:this.state.clicked == 'dashboard'?"rgb(217, 177, 101)":'',borderLeft:"unset"}} className='nohover' to="/dashboard"> <i style={{color:"black"}} className="fa fa-home"></i>Dashboard</Link></li>}
                    {(features && features.feature21 && features.feature21.permission == 1) && <li className={page[1] === 'customerDetail' ? 'active' : ''}><Link className='nohover' to="/customerDetail"> <i style={{color:"black"}} className="fa fa-user"></i>Customer Lookup </Link></li>}
                    {(features && features.feature22 && features.feature22.permission == 1) && <li onClick={()=>this.handleClick("agents")} className={page[1] === 'agents' ? 'active' : ''}><Link style={{background:this.state.clicked == 'agents'?"rgb(217, 177, 101)":'',borderLeft:"unset"}} className='nohover' to="/agents"> <i style={{color:"black"}} className="fa fa-user"></i>Agents </Link></li>}
                    {(features && features.feature23 && features.feature23.permission == 1) && <li className={page[1] === 'stores' ? 'active' : ''}><Link className='nohover' to="/stores"> <i className="fa fa-shopping-basket"></i>Stores </Link></li>}
                    {(features && features.feature24 && features.feature24.permission == 1) && <li>
                        <a href="#customerDropDown" aria-expanded="false" data-toggle="collapse" className='nohover'> <i className="fa fa-users"></i>Customers</a>
                        <ul id="customerDropDown" className={page[1] === 'customers' || page[1] === 'feedbacks' || page[1] === 'complaints' || page[1] === 'newsletter' || page[1] === 'guests' ? '' : 'collapse list-unstyled'}>
                            <li className={page[1] === 'complaints' ? 'active' : ''}><Link  className="pl-0 nohover" to="/complaints">Customers Feedback</Link></li>
                        </ul>
                    </li>}
                    {(features && features.feature25 && features.feature25.permission == 1) && <li onClick={()=>this.handleClick("orders")} className={page[1] === 'orders' ? 'active' : ''}><Link style={{background:this.state.clicked == 'orders'?"rgb(217, 177, 101)":'',borderLeft:"unset"}} className='nohover' to="/orders"> <i style={{color:"black"}} className="fa fa-user"></i>Orders</Link></li>}
                    {(features && features.feature26 && features.feature26.permission == 1) && <li className={page[1] === 'menu' ? 'active' : ''}><Link className='nohover' to="/menu"> <i style={{color:"black"}} className="fa fa-bars"></i>Menu</Link></li>}
                    {(features && features.feature27 && features.feature27.permission == 1) && <li>
                        <a className='nohover' href="#settingsDropDown" aria-expanded="false" data-toggle="collapse"> <i style={{color:"black"}} className="fa fa-cogs"></i>Settings</a>
                        <ul id="settingsDropDown" className={page[1] === 'settings'  ? '' : 'collapse list-unstyled'}>
                            <li className={page[1] === 'agent-targets' ? 'active' : ''}><Link className="pl-0 nohover" to="/agent-targets">Agent Targets</Link></li>
                        </ul>
                    </li>}
                </ul>
            </nav >
        );
    }
}
;
import {
  GET_CART,
  CLEAR_CART,
  SAVE_CART,
  STORE_ITEM_TO_CART,
  ADD_ITEM_TO_CART,
  COUPON_DATA,
  GET_TAX,
  GET_INST
} from '../actions/cartType';

const initialState: any = {
  success: '',
  cartData: [] || '',
  instructions: "",
  coupon: {},
  taxData:{},
  loader: true,
};

const cartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CART:
      return {
        ...state,
        cartData: action.payload
      };
    case GET_INST:
      return {
        ...state,
        instructions: action.instructions
      };
    case CLEAR_CART:
      return {
        ...state,
        success: action.success,
      };
    case STORE_ITEM_TO_CART:
      return {
        ...state,
        success: action.success,
        loader: action.loader,
      };
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        cartData: action.payload,
        loader: action.loader,
      };
    case COUPON_DATA:
      return {
        ...state,
        coupon: action.payload,
      };
    case GET_TAX:
      return {
        ...state,
        taxData: action.payload
      };
    // case ORDER_LIST: return {
    //     ...state,
    //     ordersData: action.payload
    // }
    // case PAYMENT_CARDS_LIST: return {
    //     ...state,
    //     cardsData: action.payload
    // }
    default:
      return state;
  }
};
export default cartReducer;

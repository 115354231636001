import {
  ALL_GROUPS,
  HANDLE_INPUT,
  MENU_ITEMS,
  ORDERS_COUNTER,
  ITEM_DETAIL,
  HANLE_ADDRESS_INPUT,
  STORES_LIST,
  HANDLE_GEOCODE_ADDRESS,
  SELECT_STORE_ID,
  GET_TAX_CASH,
  GET_TAX_CARD,
  BACKUP_STORES_LIST,
  COUPON_DATA,
  SAVE_DELIVERY_TIME,
  SAVE_DISCOUNT,
  SAVE_TAX,
  SAVE_CART_TOTAL,
  SAVE_DELIVERY_FEE,
  ORDER_PERIOD,
  STORES_OPTIONS,
  LOADING_INDICATOR,
  ORDER_RESET,
  HOUSE_NO,
} from './menuType';
import Api from '../../components/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as geolib from 'geolib';
import { ALL_STORES_LIST, SELECT_STORE } from './customerType';
import { clearCart } from './cartAction';
import jwt_decode from "jwt-decode";


toast.configure()
export const handleMenuInput = (event: { target: { name: any; value: any; }; }) => {
  return {
    type: HANDLE_INPUT,
    input: event.target
  }
}

export const handleHouseNo = (address: String) => {
  return async (dispatch: any) => {
    dispatch({
      type: HOUSE_NO,
      payload: address
    })
  }
}
export const handleAddressInput = (address: any) => {
  return {
    type: HANLE_ADDRESS_INPUT,
    address: address,
  }
}
export const handleGeoCodeAddress = (address: any) => {
  return {
    type: HANDLE_GEOCODE_ADDRESS,
    geo_address: address,
  }
}
export const ordersCounter = () => {
  return async (dispatch: any) => {
    let token: any = sessionStorage.getItem('token');
    Api.get(`call-portal/orders_counter`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ORDERS_COUNTER,
            payload: response.data.successResponse[0]
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.warning({
            text: error,
            buttonText: 'OK',
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const allGroupsList = (store_id: any) => {
  console.log("store_id for menu", store_id)
  let token:any = sessionStorage.getItem('token');
  let decoded:any = jwt_decode(token)
  return async (dispatch: any) => {
    dispatch({ type: LOADING_INDICATOR });
    let obj: any = {
      mode: 'callcenter',
      brand_id:decoded.brand_id
    }
    if (store_id) {
      obj.store_id = store_id
    }
    else {
      obj.store_id = null
    }
    Api.post(`menu/allGroups`, obj)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data)
          dispatch({
            type: ORDER_RESET,
            payload: [],
            itemsData: {},
          });
          // dispatch(clearCart())
          dispatch({
            type: ALL_GROUPS,
            payload: response.data.groups,
            groupsData: response.data.groupsData
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: MENU_ITEMS,
            payload: [],
          });
          dispatch({
            type: ALL_GROUPS,
            payload: [],
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const menuItemsListByGroupsID = (group_id: any) => {
  return async (dispatch: any) => {
    let obj = {
      mode: 'callcenter'
    }
    Api.post(`menu/items/${group_id}`, obj)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_ITEMS,
            payload: response.data.successResponse
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: MENU_ITEMS,
            payload: [],
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const getComboDetail = (combo_id: any) => {
  let token:any = sessionStorage.getItem('token');
  let decoded:any = jwt_decode(token)
  return async (dispatch: any) => {
    let obj = {
      mode: 'callcenter',
      brand_id:decoded.brand_id
    }
    Api.post(`menu/combo/${combo_id}`, obj)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data, "Item Details")
          dispatch({
            type: ITEM_DETAIL,
            payload: response.data.successResponse
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: ITEM_DETAIL,
            payload: [],
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};
export const getItemDetail = (item_id: any) => {
  return async (dispatch: any) => {
    let token:any = sessionStorage.getItem('token');
    let decoded:any = jwt_decode(token)
    let obj = {
      mode: 'callcenter',
      brand_id:decoded.brand_id
    }
    Api.post(`menu/item/${item_id}`, obj)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ITEM_DETAIL,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          dispatch({
            type: ITEM_DETAIL,
            payload: [],
          });
        } else {
          toast.warning({
            text: err.message,
            buttonText: 'OK',
          });
        }
      });
  };
};


export const findLocalStores = (lat: any, lng: any) => {
  let token:any = sessionStorage.getItem("token")
  let decoded:any = jwt_decode(token);
  console.log("herre")
  return async (dispatch: any) => {
    Api.get("menu/stores/"+decoded.brand.brand_id)
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          for (let i = 0; i < Responedata.length; i++) {
            //find distance of two coordinates
            if (Responedata[i].delivery_zone_id.kml_json) {
              if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].delivery_zone_id.kml_json))) {
                data.push(Responedata[i]);
              }
            }
          }
          //after store is find out check if the store is active and if it not active,
          //then find its backup stores array and push the backup store

          if (data.length > 0 && data[0].is_active == 0) {
            if (data[0].mainStoreId.length > 0) {
              let backupStoreData: any = [];
              data[0].mainStoreId.map((backupStore: any) => {
                if (backupStore.backupStoreId.is_active == 1) {
                  backupStoreData.push(backupStore);
                }
              })
              //sorting array with priority basis if backup stores are greater than 1
              if (backupStoreData.length > 1) {
                backupStoreData.sort((a: any, b: any) => {
                  return a.priority - b.priority
                })
              }
              //finding the backupstore object from response data and match them by store_id
              let foundedStore: any = []
              if (backupStoreData.length > 0) {
                foundedStore.push(Responedata.find((store: any) => backupStoreData[0].backupStoreId.store_id == store.store_id))
                console.log(foundedStore);
                //Save the inactive store in selected nearest store and make another list of backup stores
                let storeOption = [{
                  label: data[0].store_name,
                  value: data[0].store_id
                }]
                dispatch({
                  type: STORES_OPTIONS,
                  payload: storeOption
                })
                dispatch({
                  type: STORES_LIST,
                  payload: data,
                  selectStoreId: data.length > 0 && data[0].store_id,
                  deliveryfee: 0,
                  storeStatus: 0,
                });
                dispatch({
                  type: BACKUP_STORES_LIST,
                  payload: backupStoreData
                })
                dispatch(saveTaxDetails(data[0].state_id.state_name))
              }
            }
            else {
              let storeOption = [{
                label: data[0].store_name,
                value: data[0].store_id
              }]
              dispatch({
                type: STORES_OPTIONS,
                payload: storeOption
              })
              dispatch({
                type: STORES_LIST,
                payload: data,
                selectStoreId: data.length > 0 && data[0].store_id,
                deliveryfee: 0,
                storeStatus: 0,
              });
              dispatch(saveTaxDetails(data[0].state_id.state_name))
            }
          }
          else if (data.length > 0 && data[0].is_active === 1) {
            let storeOption = [{
              label: data[0].store_name,
              value: data[0].store_id
            }]
            dispatch({
              type: STORES_OPTIONS,
              payload: storeOption
            })
            dispatch({
              type: STORES_LIST,
              payload: data,
              selectStoreId: data.length > 0 && data[0].store_id,
              deliveryfee: 0,
              storeStatus: 0,
            });
            if (data.length > 0) {
              dispatch(saveTaxDetails(data[0].state_id.state_name))
            }
          }
          // dispatch(GetLSMitembyStoreId(data[0].store_id))
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: STORES_LIST,
            payload: data,
            deliveryfee: 0,
            storeStatus: 0
          });
        } else {
          alert("Connection Lost!!");
        }
      });
  };
};


//LocalStore with kml Coordinates Json




// Previously commited one
// export const findLocalStores = (lat: any, lng: any) => {
//   let token:any = sessionStorage.getItem('token');
//   let decoded: any = jwt_decode(token)
//   console.log("herre",lat,lng)
//   return async (dispatch: any) => {
//     Api.get("menu/stores/"+decoded.brand_id)
//       .then((response) => {
//         if (response.data.success) {
//           let data: any = [];
//           let Responedata = response.data.successResponse;
//           for (let i = 0; i < Responedata.length; i++) {
//             //find distance of two coordinates
//             if (Responedata[i].delivery_zone_id.kml_json) {
//               if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].delivery_zone_id.kml_json))) {
//                 data.push(Responedata[i]);
//               }
//             }
//           }
//           console.log("data stores",data)
//           if (data.length == 1) {
//               dispatch({
//                 type: STORES_LIST,
//                 payload: data,
//                 selectStoreId: data.length > 0 && data[0].store_id,
//                 deliveryfee: data.length > 0 && data[0].delivery_fee,
//                 storeStatus: 0,
//               });
//               let storeOption = [{
//                 label: data[0].store_name,
//                 value: data[0].store_id
//               }]
//               dispatch({
//                 type: STORES_OPTIONS,
//                 payload: storeOption
//               })
              // dispatch({
              //   type: ALL_STORES_LIST,
              //   payload: Responedata,
              // });
//           }
//           else {
//            dispatch({
//                 type: STORES_LIST,
//                 payload: response.data.successResponse,
//             })
//           }
//           //after store is find out check if the store is active and if it not active,
//           //then find its backup stores array and push the backup store

          
//           // dispatch(GetLSMitembyStoreId(data[0].store_id))
//         }
//       })
//       .catch((err) => {
//         if (err.response) {
//           let error;
//           let data: any = [];
//           if (typeof err.response.data.message === "string") {
//             error = err.response.data.message;
//           } else if (typeof err.response.data.message === "object") {
//             error = err.response.data.message[0].replace(/_/g, " ");
//           }
//           dispatch({
//             type: STORES_LIST,
//             payload: data,
//             deliveryfee: 0,
//             storeStatus: 0,
//             lat: lat,
//             lng: lng
//           });
//         } else {
//           alert("Connection Lost!!");
//         }
//       });
//   };
// };
// export const findLocalStores = (lat: any, lng: any) => {
//     console.log("lat:", lat, "lng:", lng)
//     let token:any = sessionStorage
//     let decoded:any = jwt_decode(token);
//     return async (dispatch: any) => {
//         Api.get("menu/stores/1")
//             .then((response) => {
//               console.log("response stores",response)
//                 if (response.data.success) {
//                     let data: any = [];
//                     let Responedata = response.data.successResponse;
//                     console.log(Responedata)
//                     for (let i = 0; i < Responedata.length; i++) {
//                       if (Responedata[i].delivery_zone_id.kml_json) {
//                         if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].delivery_zone_id.kml_json))) {
//                           data.push(Responedata[i]);
//                         }
//                       }
//                     }
//                     console.log("store_options",data)
//                     if (data.length == 1) {
//                         dispatch({
//                             type: STORES_LIST,
//                             payload: data,
//                             selectStoreId: data.length > 0 && data[0].store_id,
//                             deliveryfee: data.length > 0 && data[0].delivery_fee,
//                             storeStatus: 0,
//                         });
//                     }
//                     else {
//                         dispatch({
//                             type: STORES_LIST,
//                             payload: response.data.successResponse,
//                           })
//                     }
//                 }
//             })
//             .catch((err) => {
//               console.log("errors stores")
//                 if (err.response) {
//                     let error;
//                     let data: any = [];
//                     if (typeof err.response.data.message === "string") {
//                         error = err.response.data.message;
//                     } else if (typeof err.response.data.message === "object") {
//                         error = err.response.data.message[0].replace(/_/g, " ");
//                     }
//                     dispatch({
//                         type: STORES_LIST,
//                         payload: data,
//                         deliveryfee: 0,
//                         storeStatus: 0,
//                     });
//                 } else {
//                     alert("Connection Lost");
//                 }
//             });
//     };
// };

// pickup store


export const findPickupStores = (lat: any, lng: any, searchString: any) => {
  console.log(searchString)
  let token:any = sessionStorage.getItem('token');
  let decoded: any = jwt_decode(token)
  return async (dispatch: any) => {
    Api.get("menu/stores/"+decoded.brand_id)
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          let activeStores: any = [];
          Responedata.map((store: any) => {
            if (store.is_active == 1 && store.cloudKitchen == 'Disabled') {
              activeStores.push(store);
            }
          })
          const filteredStores = activeStores.filter((store: any) => {
            // return (
            //   store.address
            //     .toLowerCase()
            //     .includes(searchString.toLowerCase()) ||
            //   store.city.toLowerCase().includes(searchString.toLowerCase()) ||
            //   store.store_name
            //     .toLowerCase()
            //     .includes(searchString.toLowerCase())
            // );
            return (
              store.delivery_zone_id.city_id.name.toLowerCase() == searchString.toLowerCase()
            )
          });
          console.log("filteredStores",filteredStores)
          if (filteredStores.length == 0) {
            for (let i = 0; i < activeStores.length; i++) {
              if (activeStores[i].zone_json) {
                if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(activeStores[i].zone_json))) {
                  data.push(activeStores[i]);
                }
              }
            }
            if (data.length == 0) {
              dispatch({
                type: STORES_LIST,
                payload: [],
                selectStoreId: "",
                deliveryfee: 0,
                storeStatus: 0,
              });
            } else {
              dispatch({
                type: STORES_LIST,
                payload: data,
                selectStoreId: data[0].store_id,
                deliveryfee: 0,
                storeStatus: 0, // 1 for out of Range
              });
              dispatch(saveTaxDetails(data[0].state_id.state_name))

            }
            // Actions.selectstore();
          } else {
            dispatch({
              type: STORES_LIST,
              payload: filteredStores,
              selectStoreId: filteredStores[0].store_id,
              deliveryfee: 0,
              storeStatus: 0, // 1 for out of Range
            });
            dispatch(saveTaxDetails(filteredStores[0].state_id.state_name))

          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // Actions.selectstore();
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};
//saveSelectStoreId
export const saveSelectStoreId = (store: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SELECT_STORE_ID,
      selectStoreId: store.store_id,
    });
    dispatch(allGroupsList(store.store_id))
    dispatch({
      type: SAVE_DELIVERY_TIME,
      promiseTime: store.expected_delivery_time,
    });
    dispatch(saveTaxDetails(store.state_id.state_name))
  };
};

//save Backup stores in global state 
export const saveBackupStores = (backupStores: any) => {
  console.log(backupStores);
  return function (dispatch: any) {
    dispatch({
      type: BACKUP_STORES_LIST,
      payload: backupStores,
    });
  };
};

export const saveTaxDetails = (state_name: any) => {
  return function (dispatch: any) {
    let obj = {
      state_name: state_name
    }
    Api.post(`menu/tax`, obj)
      .then((response) => {
        if (response.data.success) {
          console.log("tax value", response.data.successResponse)
          let responseData = response.data.successResponse
          responseData.map((taxVariations: any) => {
            if (taxVariations.payment_method == 'cash') {
              dispatch({
                type: GET_TAX_CASH,
                payload: taxVariations,
              });
              console.log(taxVariations.delivery_fee, "delivery feee here")
              dispatch({
                type: SAVE_DELIVERY_FEE,
                deliveryfee: taxVariations.delivery_fee
              });
            }
            else if (taxVariations.payment_method == 'card') {
              dispatch({
                type: GET_TAX_CARD,
                payload: taxVariations,
              });
              dispatch({
                type: SAVE_DELIVERY_FEE,
                deliveryfee: taxVariations.delivery_fee
              });
            }
          })
          console.log("tax", response.data.successResponse);
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          // console.log(error);
        } else {
          // console.log("ok tax");
        }
      });
  }
}

export const applyCoupon = (data: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    Api.post(`/menu/apply_coupon`, data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COUPON_DATA,
            payload: response.data.coupon,
            successREsponse: response.data.successResponse
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.error(error);
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};


// Save customer tax value
export const SaveTaxValue = (taxAmount: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_TAX,
      taxAmount: taxAmount,
    });
  };
};

// Save customer totalDiscount
export const SaveDiscountValue = (discountValue: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_DISCOUNT,
      discountValue: discountValue,
    });
  };
};

// Save customer cart total
export const SaveCartTotal = (cart_total: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_CART_TOTAL,
      CartTotal: cart_total,
    });
  };
};
//save order period value
export const saveOrderPeriod = (period: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER_PERIOD,
      orderPeriod: period,
    });
  };
};


export const storesListForMultiSelect = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem('token');
    Api.get('/admin/storesListForMenu', {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        console.log("print console for store option", response.data)
        if (response.data.success) {

          dispatch({
            type: STORES_OPTIONS,
            payload: response.data.successResponse
          })
        }
      }).catch(err => {
        if (err.response) {
          console.log(err.response.data.message)
        } else {
          alert(err.message)
        }
      });
  }
}

export const UpdateMenuItems = (data: any) => {
  return async (dispatch: any) => {
    console.log(data)
    dispatch({
      type: MENU_ITEMS,
      payload: data.menuItemsData
    });
  }
}


export const findLocalStoreWithoutMap = (store: any) => {
  return async (dispatch: any) => {
    console.log('store', store);
    dispatch({
      type: BACKUP_STORES_LIST,
      payload: []
    })

    if (store) {
      console.log("debugger attached")
      let data: any = [];
      data.push(store)
      //after store is find out check if the store is active and if it not active,
      //then find its backup stores array and push the backup store

      if (data.length > 0 && data[0].is_active == 0) {
        if (data[0].mainStoreId.length > 0) {
          let backupStoreData: any = [];
          data[0].mainStoreId.map((backupStore: any) => {
            if (backupStore.backupStoreId.is_active == 1) {
              backupStoreData.push(backupStore);
            }
          })
          //sorting array with priority basis if backup stores are greater than 1
          if (backupStoreData.length > 1) {
            backupStoreData.sort((a: any, b: any) => {
              return a.priority - b.priority
            })
          }
          //finding the backupstore object from response data and match them by store_id
          let foundedStore: any = []
          if (backupStoreData.length > 0) {
            foundedStore.push(backupStoreData[0])
            console.log(foundedStore);
            //Save the inactive store in selected nearest store and make another list of backup stores
            let storeOption = [{
              label: data[0].store_name,
              value: data[0].store_id
            }]
            dispatch({
              type: STORES_OPTIONS,
              payload: storeOption
            })
            dispatch({
              type: STORES_LIST,
              payload: data,
              selectStoreId: data.length > 0 && data[0].store_id,
              deliveryfee: 0,
              storeStatus: 0,
            });
            dispatch({
              type: BACKUP_STORES_LIST,
              payload: backupStoreData
            })
            dispatch(saveTaxDetails(data[0].state_id.state_name))
          }
          if (backupStoreData.length == 0) {
            let storeOption = [{
              label: data[0].store_name,
              value: data[0].store_id
            }]
            // toast.error("No store detected for this address. Kindly try more structured address")
            dispatch({
              type: STORES_OPTIONS,
              payload: storeOption
            })
          }
        }
        else {
          let storeOption = [{
            label: data[0].store_name,
            value: data[0].store_id
          }]
          dispatch({
            type: STORES_OPTIONS,
            payload: storeOption
          })
          dispatch({
            type: STORES_LIST,
            payload: data,
            selectStoreId: data.length > 0 && data[0].store_id,
            deliveryfee: 0,
            storeStatus: 0,
          });
          dispatch(saveTaxDetails(data[0].state_id.state_name))
        }
      }
      else if (data.length > 0 && data[0].is_active === 1) {
        console.log('data', data);

        let storeOption = [{
          label: data[0].store_name,
          value: data[0].store_id
        }]
        dispatch({
          type: STORES_OPTIONS,
          payload: storeOption
        })
        dispatch({
          type: STORES_LIST,
          payload: data,
          selectStoreId: data.length > 0 && data[0].store_id,
          deliveryfee: 0,
          storeStatus: 0,
        });
        if (data.length > 0) {
          dispatch(saveTaxDetails(data[0].state_id.state_name))
        }
      }
      else if (data.length == 0) {
        console.log('last', data);

        toast.error("No store detected for this address. Kindly try more structured address")
        dispatch({
          type: STORES_OPTIONS,
          payload: []
        })
      }
      // dispatch(GetLSMitembyStoreId(data[0].store_id))
    }
  };
};

export const getTimezone = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        console.log('response.data.successResponse', response.data.successResponse);

        if (response.data.success) {
          // dispatch({
          //   type: GET_TIMEZONE,
          //   payload: response.data.successResponse
          // })
          return response.data.successResponse
        }
      }).catch(err => {
        if (err.message) {
          alert(err.message)
        }
      });
  }
}

export const getTimezoneForLater = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        console.log('response.data.successResponse', response.data.successResponse);

        if (response.data.success) {
          // dispatch({
          //   type: GET_TIMEZONE,
          //   payload: response.data.successResponse
          // })
          return { time: response.data.successResponse, date: response.data.successLaterResponse }
        }
      }).catch(err => {
        if (err.message) {
          alert(err.message)
        }
      });
  }
}



var ip = window.location.hostname;
var protocol = window.location.protocol == "https:" ? 'https' : 'http';
// var baseURL  = window.location.origin;
// export const API_URL = `${protocol}://${ip}:3012`;
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDTaA9BspUUJtGr_9oN7u5wEaKQIvq3XyE'
export const API_URL = `${protocol}://${ip}:3005/`;
export const BASE_URL = `${protocol}://${ip}:3005/`;
export const priceunit = 'AED '
export const currency = 'AED '
// export const BASE_URL = `${protocol}://${ip}:3012/`;

export const GET_CART = 'GET_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const SAVE_CART = 'SAVE_CART';
export const STORE_ITEM_TO_CART = 'STORE_ITEM_TO_CART';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const COUPON_DATA = 'COUPON_DATA';
export const GET_TAX = 'GET_TAX';
export const GET_INST="GET_INST"
// export const EDIT_PROFILE = "EDIT_PROFILE"
// export const GET_PROFILE = "GET_PROFILE"

import { CITIES_WEB, TRADE_ZONES_WEB, TRADE_AREAS_WEB, SET_CITY_NAME, SET_TRADE_ZONE_NAME, SET_TRADE_AREA_NAME } from '../actions/trade_zones_areas_Type';

const initialState: any = {
    cities:[],
    tradeZones:[],
    tradeAreas:[],
    cityName:'',
    tradeZoneName:'',
    tradeAreaName:'',
    tradeZoneValue:null,
    tradeAreaValue:null,
}

const trade_zones_areas_Reducer = (state = initialState, action: any) => {
    switch (action.type) {
      case CITIES_WEB:
      return {
        ...state,
        cities: action.payload,
      }

    case TRADE_ZONES_WEB:
      return {
        ...state,
        tradeZones: action.payload,
      }

    case TRADE_AREAS_WEB:
      return {
        ...state,
        tradeAreas: action.payload,
      }

    case SET_CITY_NAME:
      return {
        ...state,
        cityName: action.payload,
      }

    case SET_TRADE_ZONE_NAME:
      return {
        ...state,
        tradeZoneName: action.payload,
        tradeZoneValue: action.value
      }

    case SET_TRADE_AREA_NAME:
      return {
        ...state,
        tradeAreaName: action.payload,
        tradeAreaValue: action.value
      }
      default:
        return state;
    }
  };
export default trade_zones_areas_Reducer;
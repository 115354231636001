import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import _ from "lodash";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import axios from 'axios'
import {
  addCustomer,
  customerDetail,
  getCart,
  getStores,
  getTaxValue,
  handleAddressInput,
  handleCustomerInput,
  handleMenuInput,
  logoutUser,
  orderDetail,
  saveCart,
  validateEmail,
} from "../../redux";
import { CustomerProps, CustomerState } from "../../interfaces/customer";
import BeautyStars from "beauty-stars";
import OrderDetail from "./orderDetail";
import Complaint from "./complaint";

import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Select from 'react-select';
import {
  submitComplaint,
  TimerStart,
  trackOrder,
  stopCount,
  addAddressUser,
  addressesListk,
  isSavedAddressSelected,
} from "../../redux/actions/customerAction";
import { StartClockTimer, stopTimer } from "../../redux/actions/headerAction";
import { threadId } from "worker_threads";
import { ComplaintState } from "../../interfaces/complaint";
import moment from "moment";
import { toast } from "react-toastify";
import { complaintReasonsList } from "../../redux/actions/customerComplaintAction";
import { getCitiesWeb, getTradeZonesWeb, getTradeAreas, setTradeZoneName, setCityName, setTradeAreaName } from "../../redux/actions/trade_zones_areas_Action";
import { findLocalStores, handleHouseNo } from "../../redux/actions/menuAction";
import { brandsList, saveBrand } from "../../redux/actions/brandAction";
import { API_URL, BASE_URL } from "../../client-config";

class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  showorderItems = (id: any) => {
    let storeId: any = "";
    let stateId: any = "";
    this.props.data.orderDetail(id);
    storeId = this.props.data.orders.find((obj: any) => {
      return obj.order_id == id;
    }).store_id;
    if (storeId && storeId !== "") {
      stateId = this.props.data.stores.find((obj: any) => {
        return obj.store_id == storeId;
      }).state_id;
    }
    this.props.data.getTaxValue(stateId);
  };
  render() {
    const { row, data } = this.props;
    return (
      <div>
        <button
          title="Repeat Order"
          data-toggle="modal"
          data-target={`#repeatorder${row.order_id}`}
          className="btn btn-outline-info ml-2"
          onClick={() => this.showorderItems(row.order_id)}
        >
          <i className="fa fa-repeat"></i>
        </button>
        {/*Invoice Modal */}
        <div
          id={`repeatorder${row.order_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Order
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <OrderDetail
                  data={data}
                  brand={this.props.brand}
                  tax={
                    !_.isEmpty(this.props.data.taxdata) &&
                    this.props.data.taxdata.tax_percent
                  }
                  orderId={row.order_id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function priceFormatter(cell: any, row: any) {
  if (row.aggregator_orderId) {
    return `Rs. ${cell}`;
  } else {
    return `Rs. ${Math.round(cell)}`;
  }
}
function brandFormatter(cell: any, row: any,props:any) {
  console.log("brand props",props)
  return <img className="img-fluid rounded-circle" src={API_URL + props.brand.landing_image} />
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}

function complaintFormatter(cell: any, row: any, props: any) {
  return <Complaint row={row} data={props} />;
}
class Map extends Component<
  {
    handleAddressInput: (address: any) => {};
    findLocalStores: (lat: any, lng: any) => {};
  },
  { lat: any; lng: any }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {
    return false;
  }
  onPlaceSelected = (place: any) => {
    if (place.formatted_address) {
      const address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      this.setState({ lat: latValue, lng: lngValue });
      this.props.handleAddressInput(address);
      this.props.findLocalStores(latValue, lngValue);
    }
  };
  auto = () => {
    const AsyncMap: any = withScriptjs(
      withGoogleMap((props: any) => (
        <Autocomplete
          style={{
            width: "100%",
            height: "40px",
            marginBottom: "15px",
            paddingLeft: "16px",
            marginTop: "2px",
          }}
          onPlaceSelected={this.onPlaceSelected}
          types={["address"]}
          componentRestrictions={{ country: "pk" }}
        />
      ))
    );
    let map;
    map = (
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDGq7xWqvDvuvqwyTGJZDjfcRy1aCRmvt8&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "0px" }} />}
        containerElement={<div style={{ height: this.props.height }} />}
        mapElement={<div style={{ height: "0px" }} />}
      />
    );
    return map;
  };
  render() {
    return <div>{this.auto()}</div>;
  }
}
class CustomerDetail extends Component<CustomerProps, CustomerState> {
  customerNumber: any;
  constructor(readonly props: any) {
    super(props);

    this.state = {
      phone: "",
      fullname: "",
      email: "",
      googlesearch:'',
      placesSuggestion:[],
      feedbackType: "",
      receiptnumber: "",
      storeId: "",
      dateOfOrder: "",
      feedbackMessage: "",
      isValidPhone: "",
      orderId: "",
      newUserphone: "",
      isValidEmail: true,
      newUser: false,
      traceOrderby: "Phone",
      editable_fullAddress: "",
      sizejson: [],
      addressesList: [],
      place: "",
      editAddress_id: "",
      isUpdateBtnDisabled: true,
      area:'',building:'',room:"",
      edit_placesSuggestion:[],
      index:""
    };

    const urlPhone = localStorage.getItem("urlPhone");
    if (urlPhone && urlPhone != "") {
      window.location.href = "/customerDetail?number=" + urlPhone;
      this.submitPhone(urlPhone, "");
      localStorage.setItem("urlPhone", "");
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      this.customerNumber = params.get("number");
      if (this.customerNumber !== "" && this.customerNumber !== null) {
        this.submitPhone(this.customerNumber, "");
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmitComplaint = this.handleSubmitComplaint.bind(this);
    this.validatePhone = this.validatePhone.bind(this)
  }
  componentDidMount() {
    this.props.storesList();
    this.props.brandsList();
    this.props.complaintReasonsList();
    document.title = "SimplexCRM | Customer Detail";
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let customerNumber = params.get("number");
    if (customerNumber !== "" && customerNumber !== null) {
      this.setState({ phone: customerNumber, isValidPhone: true });
      let newUserphone = this.TrimNumber(customerNumber);
      this.setState({ newUserphone: newUserphone })
    }
    this.props.getCities()
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.callduration === nextProps.callduration) {
      nextProps.addressData.map((d:any)=>{
        Object.assign(d,{is_edit:false})
      })
      this.setState({ addressesList: nextProps.addressData })
    }
  }

  handleBrand = (e:any)=>{
    this.props.saveBrand(JSON.parse(e.target.value))
  }


  handleAddClick = (e: any,) => {
    if (this.state.sizejson.length == 1) {
      toast.error("Only one address can be added at a time")
    }
    else {
      let customer_id = sessionStorage.getItem('customer');
      const newlist: any = { full_address: "", place: "Home", customer_id: customer_id }
      const size: any = this.state.sizejson.concat(newlist)
      this.setState({ sizejson: size, isUpdateBtnDisabled: false,index:this.state.addressesList.length });
    }
    e.preventDefault()
  };
  editAddressField = (addressObj: any, index: any) => {
    let { addressesList } = this.state
    this.setState({ editable_fullAddress: addressObj.full_address, isUpdateBtnDisabled: false })
    addressesList.forEach((address: any) => {
      if (address.address_id == addressObj.address_id) {
        address.enable = true;
      }
    })
    this.setState({
      addressesList: addressesList,
      editAddress_id: addressObj.address_id,
      index:index
    })
  }

  handleVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    console.log('value', value);

    const list: any = this.state.addressesList;
    list[index][name] = value;
    this.setState({ addressesList: list });
    if (name == "pos_code") {
      this.props.checkDuplicatePosCode(value)
    }
    if (name == "erp_id") {
      this.props.checkDuplicateErpId(value)
    }
  };

  handleNewVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[index][name] = value;
    this.setState({ sizejson: list });
  };


  handleAddressSubmit = (event: any) => {
    let { houseNo, Block, Area, City } = this.props
    let { addressesList,googlesearch,area,building,room,editable_fullAddress } = this.state;
    let value = this.state.editAddress_id == '' ? googlesearch : editable_fullAddress
    let full_address = area + " " + building + " " + room + " " + value;
    if ((googlesearch === '' && area === '' && building === '' && room === '')) {
      console.log("list if",addressesList)
      this.props.addAddress(addressesList);
    }
    else {
      console.log("list else")
      let list: any = this.state.sizejson;
      
      // let obj = { full_address: event.target.value }
      let obj = { full_address: full_address }
      list.length > 0 ? list[0].full_address = full_address : list.push(obj)
      // list[0].full_address = full_address
      const dupAddressArr: any = this.state.addressesList.concat(list);
      console.log('list', dupAddressArr);
      this.setState({ addressesList: dupAddressArr })
      this.props.addAddress(dupAddressArr);
      this.setState({ sizejson: [],area:'',googlesearch:'',room:'',building:'' })
    }
    this.setState({ isUpdateBtnDisabled: true,googlesearch:'',editAddress_id:'' })

  };

  validatePhone(event: { target: { name: any; value: any; }; }) {
    // let reg = /^[0-9\b]+$/;
    // if (event.target.value === '' || reg.test(event.target.value)) {
    //   this.setState({ [event.target.name]: event.target.value, isValidPhone: true })
    // } else {
    //   event.target.value = event.target.value.slice(0, event.target.value.length-1);
    //   this.setState({ [event.target.name]: event.target.value, isValidPhone: false });
    // }

    this.setState({ [event.target.name]: event.target.value, isValidPhone: true,index:null })

  };
  TrimNumber = (phone_number: any) => {
    let str_phone_number = phone_number.toString();
    if (str_phone_number.charAt(0) === '+') {
      str_phone_number = str_phone_number.substring(3);
      str_phone_number = "0" + str_phone_number
    }
    if (str_phone_number.charAt(0) === '9') {
      str_phone_number = str_phone_number.substring(2);
      str_phone_number = "0" + str_phone_number;
    }
    if (str_phone_number.charAt(0) === ' ') {
      str_phone_number = str_phone_number.substring(3);
      str_phone_number = "0" + str_phone_number;
    }
    if (str_phone_number.length == 8) {
      str_phone_number = "0" + str_phone_number;
    }
    return str_phone_number;
  }
  submitPhone = (phone_number: any, order_id: any) => {
    let { time } = this.props;
    let refined_phone_number = phone_number.toString();
    // refined_phone_number = this.TrimNumber(phone_number);
    if (phone_number != "") {
      let obj: any = {
        // phone_number: refined_phone_number,
        brand_id:this.props.brand && this.props.brand.brand_id,
        phone_number:phone_number
      };
      // this.setState({ phone: phone_number, isValidPhone: true, newUserphone: refined_phone_number });
      this.setState({ phone: phone_number, isValidPhone: true, newUserphone: phone_number });
      this.setState({ newUser: false });
      this.props.customerDetail(obj);
    } else if (order_id != "") {
      let obj: any = {
        order_id: order_id,
      };
      this.props.trackOrder(obj);
    }
    if (time == 0) {
      this.props.StartClockTimer(time);
    }
  };

  submitPhoneTrace = (phone_number: any, order_id: any) => {
    let { time } = this.props;
    let refined_phone_number = phone_number.toString();
    let { traceOrderby } = this.state;
    refined_phone_number = this.TrimNumber(phone_number);
    if (traceOrderby === 'Phone') {
      let obj: any = {
        phone_number: refined_phone_number,
        brand_id:this.props.brand && this.props.brand.brand_id,
        mode:"callcenter"
      };
      this.setState({ phone: phone_number, isValidPhone: true });
      this.setState({ newUser: false });
      this.props.customerDetail(obj);
    } else if (traceOrderby === "OrderId") {
      let obj: any = {
        order_id: order_id,
      };
      this.props.trackOrder(obj);
    }
    if (time == 0) {
      this.props.StartClockTimer(time);
    }
    this.props.stopCount();
  };

  isCustomerReady = () => {
    let {
      firstname,
      lastname,
      email,
      isValidEmail,
      delivery_address,
      houseNo
      // Area,
      // City,
    } = this.props;
    let { newUserphone } = this.state;
    if (email !== "") {
      return (
        firstname !== "" &&
        lastname !== "" &&
        isValidEmail &&
        newUserphone !== "" &&
        houseNo !== ""
        // Area !== "" &&
        // City !== ""
      );
    } else {
      return (
        firstname !== "" &&
        lastname !== "" &&
        newUserphone !== "" &&
        houseNo !== ""
        // Area !== "" &&
        // City !== ""
      );
    }
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit = (event: any) => {
    let token:any = sessionStorage.getItem('token');
    let decoded:any = jwt.decode(token)
    let { firstname, lastname, email } = this.props;
    let { newUserphone } = this.state;
    let data: any = {
      first_name: firstname,
      last_name: lastname,
      login_name: lastname === "" ? firstname : firstname + " " + lastname,
      phone_number: newUserphone,
      geo_address:
        this.props.houseNo +
        " ",
      // this.props.Block +
      // " " +
      // this.props.Area +
      // " " +
      // this.props.City,
      brand_id:decoded.brand.brand_id,
      is_active: 1,
      is_guest: 1,
    };
    if (email !== '') {
      data.email_address = email;
    }
    this.props.addCustomer(data);
    // this.props.handleAddressInput(this.props.houseNo +  " " + this.props.tradeZoneName +" " + this.props.tradeAreaName + " " + this.props.cityName,)
    // this.setState({ newUser: false });
  };
  handleSubmitComplaint = (event: any) => {
    let { callduration } = this.props;
    const {
      fullname,
      email,
      phone,
      feedbackMessage,
      feedbackType,
      storeId,
      dateOfOrder,
      receiptnumber,
    } = this.state;
    const data: any = {
      name: fullname,
      email_address: email,
      phone_number: phone,
      receipt_number: receiptnumber,
      message: feedbackMessage,
      type: feedbackType,
      store_id: storeId,
      order_date: dateOfOrder,
    };
    callduration = callduration / 60;
    let rounded_time = Math.round(callduration * 10) / 10;
    let callStatsObj = {
      call_duration: rounded_time,
      call_status: 2,
      customer_number: sessionStorage.getItem("phone"),
      total_sale: 0,
      order_id: receiptnumber,
    };
    this.props.submitComplaint(data, callStatsObj);
    this.props.stopTimer();
  };
  isFormReady = () => {
    let { isValidPhone, phone, orderId } = this.state;
    return !isValidPhone || phone !== "" || orderId !== "";
  };

handleAddressClick = (address:any) => {
  let fulladdress:any = address.full_address
  if(fulladdress) {
    Geocode.fromAddress(fulladdress)
            .then((json: any) => {
              console.log("json",json)
              this.props.isSavedAddressSelected(true,address)
              console.log("json",json)
                var location:any = json.results[0].geometry.location;
                let latValue:any = location.lat;
                let lngValue:any = location.lng;
                this.props.findLocalStores(latValue, lngValue)
                // this.setState({ lat: latValue, lng: lngValue,placesSuggestion:[] })
                // this.props.handleGeoCodeAddress(address);
              })
            .catch((error: any) => {
                console.log("json err",error)
                // console.warn(error));
            });
  }
}

handleSearch = (e:any) => {
  this.setState({googlesearch:e.target.value})
  axios.post(`${BASE_URL}customer/autoComplete/en/${e.target.value}`)
              .then((res: any) => {
                  this.setState({ placesSuggestion: res.data.successResponse })
              })
              .catch((err: any) => {
                  // alert("something went wrong!!!")
              })
}

handleEditSearch = (e:any,index:any) => {
    const { name, value } = e.target;
    const list: any = this.state.addressesList;
    list[this.state.index]["full_address"] = value;
    this.setState({ addressesList: list });
    axios.post(`${BASE_URL}customer/autoComplete/en/${e.target.value}`)
              .then((res: any) => {
                  this.setState({ placesSuggestion: res.data.successResponse })
              })
              .catch((err: any) => {
                  // alert("something went wrong!!!")
              })
}

onPlaceClick = (place: any,index:any) => {
    const address = place.description
    this.setState({googlesearch:address,placesSuggestion:[]})
    if(this.state.editAddress_id != '') {
      const list: any = this.state.addressesList;
      list[this.state.index]["full_address"] = address;
      console.log("list new",this.state.index,list)
      this.setState({ addressesList: list });
    }
    
};

blockInvalidChar = (e: any) => {
    var regex = new RegExp("^[a-zA-Z0-9]");
    let specialkey: any = e.keyCode;
    console.log('specialkey', specialkey);

    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault()
          }
        }
      } else {
        ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
      }
    }
  }

  render() {
    let { isValidEmail, email } = this.props;
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      localStorage.setItem("urlPhone", this.customerNumber);
      return <Redirect to="/" />;
    }
    let { orders, customer, stores, storeData, addressData,brands } = this.props;
    let { newUser, sizejson, addressesList } = this.state;
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Orders Not Found",
    };

    let cities_arr: any = []
    let zones_arr: any = []
    let areas_arr: any = []
    this.props.cities_portal && this.props.cities_portal.map((city: any) => {
      cities_arr.push({ label: city.name, value: JSON.stringify(city) })
    })

    this.props.tradeZones_portal && this.props.tradeZones_portal.map((zone: any) => {
      zones_arr.push({ label: zone.delivery_zone_name, value: JSON.stringify(zone) })
    })

    this.props.tradeAreas_portal && this.props.tradeAreas_portal.map((area: any) => {
      areas_arr.push({ label: area.area_name, value: JSON.stringify(area) })
    })
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Customers Management</h4>
                  {/* <div>
                  <button className="btn btn-danger mr-3" style={{ padding: '0 15px' }} onClick={()=> this.submitPhoneTrace(this.state.phone,this.state.orderId)} ><i className="fa fa-refresh"></i></button>
                  </div> */}
                </div>
              </div>
            </header>
            {/* <div className="breadcrumb-holder container-fluid"> */}
            {/* <ul className="breadcrumb"> */}
            <section className="tables mt-4 no-padding">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <Tabs>
                          <TabLink
                            activeClassName="btn btn-danger"
                            className="btn"
                            to="tab1"
                          >
                            New Order
                          </TabLink>
                          <TabLink
                            activeClassName="btn btn-danger"
                            className="btn"
                            to="tab2"
                          >
                            Trace Order
                          </TabLink>
                          {/* <TabLink activeClassName="btn btn-danger" className='btn' to="tab3">Register Feedback</TabLink> */}

                          <TabContent className="mt-4" for="tab1">
                            <div className="form-group row">
                              <label className="col-sm-2 form-control-label">
                                Enter Phone Number:
                              </label>
                              <div className="col-sm-10">
                                <input
                                  id="inputHorizontalSuccess"
                                  value={this.state.phone}
                                  type="tel"
                                  maxLength={13}
                                  name="phone"
                                  required
                                  placeholder="Phone Number"
                                  className="form-control form-control-success"
                                  onChange={this.validatePhone}
                                />
                                {this.state.isValidPhone == false &&
                                  this.state.phone !== "" && (
                                    <small className="form-text text-danger">
                                      Invalid Phone Number
                                    </small>
                                  )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-2 form-control-label">
                                Select Brand:
                              </label>
                              <div className="col-sm-10">
                              <select
                                name="brand"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleBrand}
                              >
                                <option value="">Select Brand</option>
                                {brands &&
                                  brands.map(
                                    (brand:any, index:any) =>
                                      brand.is_active == 1 && (
                                        <option key={index} value={JSON.stringify(brand)}>
                                           {brand.brand_name}
                                        </option>
                                      )
                                  )}
                              </select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-2 form-control-label">
                                Enter Order Id:
                              </label>
                              <div className="col-sm-10">
                                <input
                                  id="inputHorizontalSuccess"
                                  type="tel"
                                  maxLength={11}
                                  name="orderId"
                                  required
                                  placeholder="Order Id"
                                  className="form-control form-control-success"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>

                            

                            <div className="d-flex justify-content-between">
                              <div className="form-group">
                                <input
                                  type="submit"
                                  value="Add Customer"
                                  onClick={() => {
                                    this.setState({ newUser: true });
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="submit"
                                  disabled={this.state.phone.length >= 8 ? false : true}
                                  value="Submit"
                                  onClick={() => {
                                    this.submitPhone(
                                      this.state.phone,
                                      this.state.orderId
                                    );
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                            </div>
                          </TabContent>

                          <TabContent className="mt-4" for="tab2">
                            <div className="form-group row">
                              <div className="col-4">
                                <input
                                  id="radioPickup"
                                  type="radio"
                                  checked={this.state.traceOrderby === "Phone"}
                                  value="Phone"
                                  onChange={() => {
                                    this.setState({ traceOrderby: "Phone" });
                                  }}
                                  name="Phone"
                                  className="radio-template"
                                />
                                <label className="form-control-label">
                                  Track Order By Phone Number
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  id="radioPickup"
                                  type="radio"
                                  checked={this.state.traceOrderby === "OrderId"}
                                  value="OrderId"
                                  onChange={() => {
                                    this.setState({ traceOrderby: "OrderId" });
                                  }}
                                  name="OrderId"
                                  className="radio-template"
                                />
                                <label className="form-control-label">
                                  Track Order By Order ID
                                </label>
                              </div>
                            </div>

                            {this.state.traceOrderby === 'Phone' &&
                              <div className="form-group row">
                                <label className="col-sm-2 form-control-label">
                                  Track Order By Phone Number:
                                </label>
                                <div className="col-sm-10">
                                  <input
                                    id="inputHorizontalSuccess"
                                    type="tel"
                                    maxLength={13}
                                    name="phone"
                                    required
                                    placeholder="Phone Number"
                                    className="form-control form-control-success"
                                    onChange={this.validatePhone}
                                  />
                                  {this.state.isValidPhone == false &&
                                    this.state.phone !== "" && (
                                      <small className="form-text text-danger">
                                        Invalid Phone Number
                                      </small>
                                    )}
                                </div>
                              </div>
                            }
                            {this.state.traceOrderby === "OrderId" &&
                              <div className="form-group row">
                                <label className="col-sm-2 form-control-label">
                                  Track Order By Order Id:
                                </label>
                                <div className="col-sm-10">
                                  <input
                                    id="inputHorizontalSuccess"
                                    type="tel"
                                    maxLength={11}
                                    name="orderId"
                                    required
                                    placeholder="Order Id"
                                    className="form-control form-control-success"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            }
                            <div className="d-flex justify-content-between">
                              <div className="form-group">
                                <input
                                  type="submit"
                                  value="Add Customer"
                                  onClick={() => {
                                    this.setState({ newUser: true });
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="submit"
                                  value="Submit"
                                  disabled={!this.isFormReady()}
                                  onClick={() => {
                                    this.submitPhoneTrace(
                                      this.state.phone,
                                      this.state.orderId
                                    );
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                            </div>
                          </TabContent>
                          <TabContent className="mt-4" for="tab3">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="panel panel-default">
                                    <div className="panel-body">
                                      <div className="col-md-12">
                                        <div className="form-row">
                                          <div className="form-group col-md-12">
                                            {" "}
                                            <br />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              {" "}
                                              Full Name{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="fullname"
                                              placeholder="Full Name"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Phone{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="phone"
                                              placeholder="Phone"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>

                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Email{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="email"
                                              placeholder="Email"
                                              style={{ fontSize: "15px" }}
                                              type="email"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Select Ordering Store{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>

                                            <select
                                              onChange={this.handleInputChange}
                                              className="custom-select mb-3 w-100"
                                              id="round1"
                                              name="storeId"
                                            >
                                              <option value="">
                                                Select Store
                                              </option>

                                              {stores &&
                                                stores.map(
                                                  (store: any, index: any) => (
                                                    <option
                                                      key={index}
                                                      value={store.store_id}
                                                    >
                                                      {store.store_name}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </div>

                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Order Date{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>{" "}
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              type="date"
                                              name="dateOfOrder"
                                              required
                                              data-msg="Please Enter Order Date"
                                              className="form-control"
                                              id="round"
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              {" "}
                                              Order ID{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="receiptnumber"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              placeholder="Enter receipt number"
                                              className="form-control"
                                              id="round"
                                            />
                                          </div>
                                          <div className="form-group col-md-12">
                                            <label id="txt">
                                              {" "}
                                              Feedback Type{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <select
                                              onChange={this.handleInputChange}
                                              className="custom-select mb-3 w-100"
                                              id="round1"
                                              name="feedbackType"
                                            >
                                              <option value="">
                                                Select Feedback Type
                                              </option>
                                              <option value="Comments">
                                                Comment
                                              </option>
                                              <option value="Suggestions">
                                                Suggestion
                                              </option>
                                              <option value="Complaint">
                                                Complaint
                                              </option>
                                              <option value="Questions">
                                                Question
                                              </option>
                                            </select>
                                          </div>
                                          <div className="form-group col-md-12">
                                            <label id="txt">
                                              Feedback{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <textarea
                                              onChange={this.handleInputChange}
                                              className="form-control"
                                              name="feedbackMessage"
                                              id="round2"
                                              rows={4}
                                              cols={120}
                                            ></textarea>
                                          </div>
                                          <div className="form-group col-md-4">
                                            <button
                                              onClick={
                                                this.handleSubmitComplaint
                                              }
                                              id="h"
                                              style={{
                                                borderRadius: "10px",
                                                backgroundColor: "#c00a27",
                                                color: "white",
                                                borderColor: "#c00a27",
                                              }}
                                              type="submit"
                                              className="btn btn-primary"
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabContent>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* {!_.isEmpty(storeData) &&
                            <div className="card-body">
                                <div className='container-fluid'>
                                    <p>Order Details:</p>
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order ID</label>
                                                <input type="email" disabled value={storeData.order_id} placeholder="Customer Name" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Total</label>
                                                <input type="email" disabled value={storeData.order_grossprice} placeholder="Phone Number" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Method</label>
                                                <input type="email" disabled value={storeData.delivery_status} placeholder="Email Address" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Status</label>
                                                <input type="email" disabled value={storeData.order_status_description} placeholder="Email Address" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col">
                                                <label className="form-control-label">Delivery Address</label>
                                                <input type="email" name="delivery_address" disabled value={storeData.delivery_address} placeholder="Customer Name" className="form-control" />
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        } */}
            {!_.isEmpty(customer) && (
              // <div className="card-body">
              //     <div className='container-fluid'>
              //         <p>Customer Details:</p>
              //         <form>
              //             <div className="form-group row">
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Name</label>
              //                     <input type="email" disabled value={customer.login_name} placeholder="Customer Name" className="form-control" />
              //                 </div>
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Phone</label>
              //                     <input type="email" disabled value={customer.phone_number} placeholder="Phone Number" className="form-control" />
              //                 </div>
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Email</label>
              //                     <input type="email" disabled value={customer.email_address} placeholder="Email Address" className="form-control" />
              //                 </div>
              //             </div>
              //             {customer.geo_address && customer.geo_address.full_address &&
              //                 <div className="form-group row">
              //                     <div className="col">
              //                         <label className="form-control-label">Delivery Address</label>
              //                         <input type="email" name="delivery_address" disabled value={customer.geo_address.full_address} onChange={this.props.handleAddressInput(customer.geo_address.full_address)} placeholder="Customer Name" className="form-control" />
              //                     </div>

              //                 </div>
              //             }
              //         </form>
              //     </div>
              // </div>
              <section className="pt-2 pb-2">
                <div className="container-fluid">
                  <div id="accordion">
                    <div className="card-header no-padding" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Customer Information
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="project bg-white has-shadow ">
                        <div className="card-body">
                          <div className="container-fluid">
                            <form>
                              <div className="form-group row">
                                <div className="col-lg-4">
                                  <label className="form-control-label">
                                    Name
                                  </label>
                                  <input
                                    type="email"
                                    disabled
                                    value={customer.login_name}
                                    placeholder="Customer Name"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-lg-4">
                                  <label className="form-control-label">
                                    Phone
                                  </label>
                                  <input
                                    type="email"
                                    disabled
                                    value={customer.phone_number}
                                    placeholder="Phone Number"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-lg-4">
                                  <label className="form-control-label">
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    disabled
                                    value={customer.email_address}
                                    placeholder="Email Address"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                                {addressesList && addressesList.length > 0 ?
                                  addressesList.map((address: any, ind: any) => {
                                    return (
                                      <>
                                        <div key={ind} className="form-group row no-padding ml-3">
                                          <div className="col">
                                            <label className="form-control-label">
                                              Delivery Address
                                            </label>
                                            <div className="d-flex">
                                              <input style={{ height: '25px', transform: 'scale(1.5)' }} className="form-check-input" onChange={()=>this.handleAddressClick(address)} type="radio" name="delivery_address" id={'delivery_address' + ind} />
                                              {/* <input
                                                type="text"
                                                name="full_address"
                                                value={
                                                  address.full_address
                                                }
                                                disabled={address.enable ? !address.enable : true}
                                                onChange={e => this.handleVariantInputChange(e, i)}
                                                placeholder="Delivery Address"
                                                className="form-control"
                                              /> */}
                                              <input
                                              autoComplete='off'
                                              style={{height:"calc(1.8125rem + 10px)",borderRadius:'5px'}}
                                              name="full_address"
                                              className="form-control form-control-sm search-wrapper"
                                              value={
                                                  address.full_address
                                              //  !address.enable ? address.full_address : this.state.editable_fullAddress
                                              }
                                              disabled={address.enable ? !address.enable : true}
                                              onChange={(e:any) => this.handleEditSearch(e,ind)}
                                              placeholder="Enter your full address"
                                            />
                                            {this.state.placesSuggestion.length>0 && <div className="address-suggestion mt-4">
                                              <ul style={{padding:"10px"}}>
                                              {this.state.placesSuggestion.map((place: any) => {
                                                          return (
                                                              <>
                                                                  <li className='suggestions' style={{listStyle:"none"}}
                                                                      onClick={() => this.onPlaceClick(place,ind)}
                                                                  >
                                                                      <span className="upper-text">
                                                                          <strong>
                                                                              {place.structured_formatting.main_text}
                                                                          </strong>
                                                                      </span>
                                                                      <span className="bottom-text">
                                                                          {place.structured_formatting.secondary_text}
                                                                      </span>
                                                                  </li>
                                                              </>
                                                          )
                                                      })
                                                      }
                                              </ul>
                                            </div>}

                                              <button className="btn btn-sm btn-primary ml-2" type="button" onClick={() => this.editAddressField(address, ind)}><i className="fa fa-edit"></i></button>
                                              {addressesList.length - 1 === ind && <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleAddClick(e)}><i className="fa fa-plus"></i></button>}
                                            </div>

                                          </div>

                                        </div>
                                        <div className="form-group row">
                                          <div className="col-4">
                                            <select name="place" onChange={e => this.handleVariantInputChange(e, ind)} className="form-control mt-2" required data-msg="Please select address type" >
                                              <option key={'home' + ind} value="Home"  {...(address.place == 'Home') && { selected: true }}>Home</option>
                                              <option key={'office' + ind} value="Office" {...(address.place == 'Office') && { selected: true }}>Office</option>
                                              <option key={'other' + ind} value="Other"  {...(address.place == 'Other') && { selected: true }}> Other</option>
                                            </select>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })
                                  :
                                  <>
                                    {sizejson.length === 0 &&
                                      <div className="row">
                                        <div className="col">
                                          <button className="btn btn-sm btn-primary ml-2" onClick={(e: any) => this.handleAddClick(e)}><i className="fa fa-plus"></i></button>
                                        </div>
                                      </div>
                                    }
                                  </>
                                }
                              </div>
                              {sizejson && sizejson.length > 0 && sizejson.map((x: any, i: any) => {
                                return (
                                  <>
                                     <div className="row no-padding">
                                        <div className="col">
                                          <div className="form-group">
                                            <label className="form-control-label">
                                            Select Your Location for Delivery
                                            </label>
                                            <input
                                              autoComplete='off'
                                              style={{height:"calc(1.8125rem + 10px)",borderRadius:'5px'}}
                                              name="Additional_address_info"
                                              className="form-control form-control-sm search-wrapper"
                                              value={this.state.googlesearch}
                                              onChange={this.handleSearch}
                                              placeholder="Enter your full address"
                                            />
                                            {this.state.placesSuggestion.length>0 && <div className="address-suggestion mt-4">
                                              <ul style={{padding:"10px"}}>
                                              {this.state.placesSuggestion.map((place: any) => {
                                                          return (
                                                              <>
                                                                  <li className='suggestions' style={{listStyle:"none"}}
                                                                      onClick={() => this.onPlaceClick(place,i)}
                                                                  >
                                                                      <span className="upper-text">
                                                                          <strong>
                                                                              {place.structured_formatting.main_text}
                                                                          </strong>
                                                                      </span>
                                                                      <span className="bottom-text">
                                                                          {place.structured_formatting.secondary_text}
                                                                      </span>
                                                                  </li>
                                                              </>
                                                          )
                                                      })
                                                      }
                                              </ul>
                                            </div>}
                                            {/* <img style={{ width: '30px', height: '30px', cursor: "pointer",float:'right',position:'relative',bottom:"31px",marginRight:"10px" }} className="" title="Current Location" src="assets/img/MapPin.svg" alt="pin"
                                              onClick={() => { this.getCurrentPositionMark() }}
                                          /> */}

                                            {/* <button
                                              onClick={this.FormatAddress}
                                              disabled={!this.isAddressReady("delivery")}
                                              className="btn-danger btn mt-2"
                                            >
                                              Submit
                                            </button> */}

                                          </div>
                                        </div>
                                    </div>

                                    <div style={{padding:"unset"}} className="row mb-0">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                              Area{" "}
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.area}
                                onChange={(e) => {
                                  this.setState({area:e.target.value})
                                }}
                                placeholder="Enter Delivery Address"
                              />
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                              Building Name/Building Number{" "}
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.building}
                                onChange={(e) => {
                                  this.setState({building:e.target.value})
                                }}
                                placeholder="Enter Delivery Address"
                              />
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                              Villa/Flat No.{" "}
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.room}
                                onChange={(e) => {
                                  this.setState({room:e.target.value})
                                }}
                                placeholder="Enter Delivery Address"
                              />
                            </div>
                          </div>
                          
                        </div>

                                    <div className="form-group row">
                                      <div className="col">
                                        <select name="place" onChange={e => this.handleNewVariantInputChange(e, i)} className="form-control mt-2" required data-msg="Please select address type" >
                                          <option key={'home' + i} value="Home" >Home</option>
                                          <option key={'office' + i} value="Office">Office</option>
                                          <option key={'other' + i} value="Other"> Other</option>

                                        </select>

                                      </div>

                                    </div>

                                  </>
                                )
                              })
                              }
                              {(sizejson.length > 0 || addressesList.length > 0) &&
                                <div className="row">
                                  <div className="col">
                                    <button disabled={this.state.isUpdateBtnDisabled} className="btn btn-danger px-4" type="button" onClick={this.handleAddressSubmit}>
                                      Update
                                    </button>
                                  </div>
                                </div>
                              }
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <div className="container-fluid">
              <div className="d-flex ml-4">
                <div className="form-group">
                  <Link to="/menu">
                    <button className="btn btn-danger px-4">Start Order</button>
                  </Link>
                </div>
              </div>
            </div>
            {this.state.newUser === true && (
              <div>
                <section className="forms pt-3">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    First name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="firstname"
                                    type="text"
                                    name="firstname"
                                    required
                                    data-msg="Please enter First Name"
                                    className="input-material"
                                    onChange={this.props.handleCustomerInput}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="lastname"
                                    type="text"
                                    name="lastname"
                                    required
                                    data-msg="Please enter Last Name"
                                    className="input-material"
                                    onChange={this.props.handleCustomerInput}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Email
                                  </label>
                                  <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    required
                                    data-msg="Please enter Email"
                                    className="input-material"
                                    onChange={this.props.validateEmail}
                                  />
                                  {isValidEmail == false && email !== "" && (
                                    <small className="form-text text-danger">
                                      email is not correct
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Phone <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="phone"
                                    type="tel"
                                    maxLength={13}
                                    name="newUserphone"
                                    defaultValue={this.state.newUserphone}
                                    required
                                    data-msg="Please enter Phone"
                                    className="input-material"
                                    onChange={this.validatePhone}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    House/Flat/Plot
                                  </label>
                                  <input
                                    id="houseNO"
                                    type="text"
                                    name="houseNo"
                                    required
                                    data-msg="Please enter House Number"
                                    className="input-material"
                                    onChange={(e) => this.props.setHouseNo(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>


                            {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Enter Address:</label>
                                                            <Map  findLocalStores={this.props.findLocalStores} handleAddressInput={this.props.handleAddressInput} />
                                                            <input id="inputHorizontalSuccess" type="text" name="address" required placeholder="Delivery Address" className="form-control form-control-success" onChange={this.props.handleAddressInput} />
                                                        </div>
                                                    </div>
                                                </div> */}
                            <div className="row">
                              {/* <div className="col-3">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    House/Flat/Plot{" "}
                                  </label>
                                  <input
                                    className="form-control form-control-sm"
                                    name="houseNo"
                                    onChange={this.props.handleMenuInput}
                                    placeholder="Enter House/Flat/Plot"
                                  />
                                </div>
                              </div> */}

                              {/* <div className="col-3">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Block/Street{" "}
                                  </label>
                                  <input
                                    className="form-control form-control-sm"
                                    name="Block"
                                    onChange={this.props.handleMenuInput}
                                    placeholder="Enter Block/Street"
                                  />
                                </div> */}

                              {/* <div className="col-3">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    City/Town{" "}
                                  </label>
                                    <Select
                                        name="cities"
                                        options={cities_arr}
                                        className="text-capitalize basic-multi-select mt-2"
                                        classNamePrefix="select"
                                        isLoading={cities_arr.length == 0 ? true : false}
                                        onChange={
                                          this.handleCityInput
                                        }
                                      />
                                </div>

                              </div> */}
                              {/* <div className="col-3">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Area/Place{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    className="form-control form-control-sm"
                                    name="Area"
                                    onChange={this.props.handleMenuInput}
                                    placeholder="Enter Area/Road/Place"
                                  />
                                </div>
                              </div> */}

                              {/* <div className="col-3">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Area/Place{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                        name="zones"
                                        options={zones_arr}
                                        className="text-capitalize basic-multi-select mt-2"
                                        classNamePrefix="select"
                                        isLoading={zones_arr.length == 0 ? true : false}
                                        onChange={
                                          this.handleZone
                                        }
                                      />
                                </div>
                              </div> */}


                              {/* <div className="col-3">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    City/Town{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    className="form-control form-control-sm"
                                    name="City"
                                    onChange={this.props.handleMenuInput}
                                    placeholder="Enter City/Town"
                                  />
                                </div> */}


                              {/* <div className="col-3">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Block/Street{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                        name="areas"
                                        options={areas_arr}
                                        className="text-capitalize basic-multi-select mt-2"
                                        classNamePrefix="select"
                                        isLoading={areas_arr.length == 0 ? true : false}
                                        onChange={
                                          this.handleArea
                                        }
                                   />
                                </div>
                              </div> */}
                              <div className="form-group"></div>
                            </div>

                            <div className="form-group d-flex justify-content-end mt-4">
                              <button
                                className="btn btn-danger"
                                disabled={!this.isCustomerReady()}
                                onClick={this.handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            <section className="tables pt-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          version="4"
                          data={orders}
                          search={true}
                          pagination={orders.length > 10 && true}
                          options={options}
                          hover
                        >
                          <TableHeaderColumn
                            dataField="order_id"
                            csvHeader="Order Id"
                            dataSort={true}
                            isKey
                          >
                            Order Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="brand"
                            csvHeader="Brand"
                            dataFormat={brandFormatter}
                            dataSort={true}
                            formatExtraData={this.props}
                          >
                            Brand
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="store_name"
                            csvHeader="Store"
                            columnTitle
                          >
                            Store
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="delivery_status"
                            csvHeader="Order Type"
                            columnTitle
                          >
                            Order Type
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_channel"
                            csvHeader="Order Channel"
                            columnTitle
                          >
                            Order Channel
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataField='action' width='150' dataFormat={selectDriverEditor} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataField='action' width='180' dataFormat={selectStatusEditor} formatExtraData={this.props}>Status</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataField="login_name"
                            csvHeader="Customer Name"
                            columnTitle
                          >
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="phone_number"
                            csvHeader="Phone"
                            columnTitle
                          >
                            Phone
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="delivery_address"
                            csvHeader="Address"
                            width="150"
                            columnTitle
                          >
                            Address
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_grossprice"
                            dataFormat={priceFormatter}
                            csvHeader="Price"
                          >
                            Order Price
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn width='100' columnTitle>Delivery Zone</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataField="payment_method"
                            csvHeader="Payment Method"
                            columnTitle
                          >
                            Payment Method
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_status_description"
                            csvHeader="Order Status"
                            columnTitle
                          >
                            Order Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="date_created"
                            csvHeader="Order Recieved"
                            dataFormat={dateFormatter}
                            columnTitle
                          >
                            Order Recieved
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="action"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Repeat Order
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="action"
                            width="70"
                            dataFormat={complaintFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
function dateFormatter(cell: any) {
  var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
  DateCreated = DateCreated[1] + " , " + DateCreated[0];
  return (
    <div>
      <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    orders: state.customer.ordersList,
    orderdata: state.customer.orderDetail,
    cart: state.cart.cartData,
    customer: state.customer.customerData,
    taxdata: state.cart.taxData,
    stores: state.customer.storesList,
    firstname: state.customer.firstname,
    lastname: state.customer.lastname,
    email: state.customer.email,
    phone: state.customer.phone,
    delivery_address: state.menu.delivery_address,
    isValidEmail: state.customer.isValidEmail,
    time: state.customer.clockCount,
    houseNo: state.menu.houseNo,
    Block: state.menu.Block,
    Area: state.menu.Area,
    City: state.menu.City,
    storeData: state.customer.storeData,
    callduration: state.customer.clockCount,
    addressData: state.customer.addressData,
    reasonsList: state.customerComplaint.complaintReasonList,
    cities_portal: state.trade_zones_areas.cities,
    tradeZones_portal: state.trade_zones_areas.tradeZones,
    tradeAreas_portal: state.trade_zones_areas.tradeAreas,
    cityName: state.trade_zones_areas.cityName,
    tradeZoneName: state.trade_zones_areas.tradeZoneName,
    tradeAreaName: state.trade_zones_areas.tradeAreaName,
    is_saved_address_selected:state.customer.is_saved_address_selected,
    brands:state.brand.brands,
    brand:state.brand.selected_brand
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    orderDetail: function (orderId: any) {
      dispatch(orderDetail(orderId));
    },
    customerDetail: (data: any) => {
      dispatch(customerDetail(data));
    },
    handleCustomerInput: (event: any) => {
      dispatch(handleCustomerInput(event));
    },
    addCustomer: (data: any) => {
      dispatch(addCustomer(data));
    },
    validateEmail: (event: any) => {
      dispatch(validateEmail(event));
    },
    handleAddressInput: (address: any) => {
      dispatch(handleAddressInput(address));
    },
    storesList: function () {
      dispatch(getStores());
    },
    getTaxValue: (state_id: any) => {
      dispatch(getTaxValue(state_id));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    StartClockTimer: function (time: any) {
      dispatch(StartClockTimer(time));
    },
    handleMenuInput: (event: any) => {
      dispatch(handleMenuInput(event));
    },
    submitComplaint: function (data: any, callStatsObj: any) {
      dispatch(submitComplaint(data, callStatsObj));
    },
    trackOrder: function (data: any) {
      dispatch(trackOrder(data));
    },
    stopTimer: function () {
      dispatch(stopTimer());
    },
    stopCount: function () {
      dispatch(stopCount());
    },
    addAddress: (data: any) => {
      dispatch(addAddressUser(data));
    },
    addressesListk: function () {
      dispatch(addressesListk());
    },
    complaintReasonsList: function () {
      dispatch(complaintReasonsList());
    },
    getCities: function () {
      dispatch(getCitiesWeb());
    },
    getTradeZones: function (id: any) {
      dispatch(getTradeZonesWeb(id))
    },
    getTradeAreas: function (id: any) {
      dispatch(getTradeAreas(id))
    },
    setTradeZoneName: function (name: String,value:any) {
      dispatch(setTradeZoneName(name,value))
    },
    setTradeAreaName: function (name: String,value:any) {
      dispatch(setTradeAreaName(name,value))
    },
    setCityName: function (name: String) {
      dispatch(setCityName(name))
    },
    setHouseNo: function (address: String) {
      dispatch(handleHouseNo(address))
    },
    isSavedAddressSelected:(value:any,address:any) => {
      dispatch(isSavedAddressSelected(value,address))
    },
    brandsList:() => {
      dispatch(brandsList())
    },
    saveBrand:(brand_id:any)=>{
      dispatch(saveBrand(brand_id))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);

import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import jwt from 'jsonwebtoken'
export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount(){
    }
    render() {
        let token:any = sessionStorage.getItem("token")
        let decoded:any = jwt.decode(token)
        let color:any
        if(decoded && decoded.brand && decoded.brand.color) {
            color = decoded.brand.color
        } else {
            color = '#dc3545'
        }
        return (
            <footer style={{background:"#d9b165"}} className="main-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <p> &copy; Simplex 2020 - {new Date().getFullYear()} All rights reserved</p>
                        </div>
                        <div className="col-sm-6 text-right">
                        <p>Powered By <Link to="#" className="external">SimpleX Technology Solutions</Link> </p>
                        </div>
                    </div>
                </div>
            </footer >
        );
    }
};
import React, { Component } from "react";
import Sidebar from "../../components/sidebar";
import Topbar from "../../components/topbar";
import Footer from "../../components/footer/main";
//  import "./order.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import OrderDetail from "../customerDetail/orderDetail";
import _, { dropRight } from "lodash";
import { OrderProps, OrderState } from "../../interfaces/order";
import { connect } from "react-redux";
import {
  getCart,
  getStores,
  getTaxValue,
  //   addCustomer,
  //   customerDetail,
  //   getCart,
  //   getStores,
  //   getTaxValue,
  //   handleAddressInput,
  //   handleCustomerInput,
  logoutUser,
  orderDetail,
  //   orderDetail,
  //   saveCart,
  //   validateEmail,
  ordersList,
  saveCart
} from "../../redux";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Complaint from '../customerDetail/complaint';
import Invoice from './FbrInvoice/fbrInvoice';

import { cancelOrdersReasons, getAgentStats, luanchTimer, orderStatusList, searchOrder, showorderItems, stopTimer, updateOrder } from "../../redux/actions/orderAction";
import moment from "moment";
import jwt from 'jsonwebtoken'
import { complaintReasonsList } from "../../redux/actions/customerComplaintAction";
import { API_URL, priceunit } from "../../client-config";
import { toast } from "react-toastify";
import { ReactTimer } from "./timer/timer";
import InvoiceAggregator from './invoice-aggregator';
class ActionFormatter extends Component<{ row: any,action_state:any }, { selectedOrderId: any,checkTimer:any }> {
  constructor(readonly props: any) {
      super(props);
      this.state = {
          selectedOrderId: "",
          checkTimer:false
      }
  }
  showorderItems = (id: any,channel:any) => {
      this.props.data.stopTimer();
        if (this.props.data.timerStatus == true) {
            this.setState({ checkTimer: true })
        }
      let storeId: any = "";
      let stateId: any = "";
      this.props.data.showorderItems(id, this.props.data.orders,channel);
      storeId = this.props.data.orders.find((obj: any) => {
          return obj.order_id == id;
      }).store_id;
      if (storeId && storeId !== "") {
          stateId = this.props.data.stores && this.props.data.stores.find((obj: any) => {
              return obj.store_id == storeId;
          });
      }
      // if (stateId !== "") {
      //     tax = this.props.data.states.find((obj: any) => {
      //         return obj.state_id == stateId;
      //     }).tax_percent;
      // }
      this.setState({ selectedOrderId: id });
  }
  handleTimer = () => {
    console.log("this.props.action_state.days",this.props)
    if (this.state.checkTimer == true) {
        this.props.data.luanchTimer(1,this.props.action_state.days);
    }
}
  render() {
    const { row, data } = this.props;
    return (
      <div>
        {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
        <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id, row.order_channel)}><i className="fa fa-file-pdf-o"></i></button>
        {/* <!-- Modal--> */}
        {/*Invoice Modal */}
        <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
          <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                <button onClick={() => this.handleTimer()} type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                {
                  row.brand_id == 1 ?

                    <Invoice data={data} orderId={this.state.selectedOrderId} /> :

                    <InvoiceAggregator data={data} orderId={this.state.selectedOrderId} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class StatusEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, cancelReason: any,otherReason:any, orderstatus: any }> {
  openModal: any;
  closeModal: any;
  constructor(props: any) {
      super(props);
      this.state = {
          orderstatus: "",
          cancelReason: "",
          otherReason:""
      }
      this.handleInputChange = this.handleInputChange.bind(this);
      this.cancelOrder = this.cancelOrder.bind(this);
  }
  componentDidMount() {
      const { row } = this.props
      this.setState({ orderstatus: row.order_status_code })
  }
  handleSave = () => {
      const { row } = this.props
      const { orderstatus } = this.state;
      

      console.log(orderstatus,"orderstatus");
      console.log(row,"orderstatus");
      
      let orderDate = moment(row.date_created).local().format('DD/MM/YYYY HH:mm:ss')
      let currentDate = moment().format('DD/MM/YYYY HH:mm:ss');
      let orderDateforComp = moment(row.date_created).local().format('YYYY-MM-DD');
      let currentDateforComp = moment().format('YYYY-MM-DD');
      var createdTime = moment(row.date_created).local().format('HH:mm');
      var currentTime = moment().local().format('HH:mm');
      var startTime = moment(createdTime, "HH:mm");
      var endTime = moment(currentTime, "HH:mm");
      var duration: any = moment.duration(endTime.diff(startTime));
      var minutes = parseInt(duration.asMinutes());
      var minutesduration = 0;
      if (moment(currentDateforComp).isAfter(orderDateforComp)) {
          var ms = moment(currentDate, "DD/MM/YYYY HH:mm:ss").diff(moment(orderDate, "DD/MM/YYYY HH:mm:ss"));
          var d = moment.duration(ms);
          minutesduration = Math.floor(d.asMinutes());
      } else {
          minutesduration = minutes;
      }
      let deliverytime: any = row.delivery_time_json != null ? JSON.parse(row.delivery_time_json) : { pending: 0, kitchen: 0, ready: 0, dispatched: 0 }
      if (row.delivery_status == "Delivery") {
          if (orderstatus == row.order_status_code) {
              toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
          } 
          // else {
              // if (orderstatus == 3) {
              //     if (row.delivery_boy !== "" && row.delivery_boy !== null) {
              //         deliverytime["ready"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
              //         const data = {
              //             channel:row.order_channel,
              //             order_status_code: orderstatus,
              //             delivery_status: row.delivery_status,
              //             delivery_time_json: JSON.stringify(deliverytime),
              //             orderData: row
              //         }
              //         this.props.data.updateOrder(row.order_id, data, this.props.data.history)
              //     } else {
              //         alert('Please Assign the order to driver first')
              //     }
              // } else if (orderstatus == 6) {
              //     this.openModal.click();
              // } 
              else {
                  // if (orderstatus == 2) {
                  //     deliverytime["pending"] = minutesduration;
                  // }
                  // if (orderstatus == 4) {
                  //     deliverytime["dispatched"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                  // }
                  // if (orderstatus == 8) {
                  //     deliverytime["kitchen"] = minutesduration - deliverytime.pending;
                  // }
                  const data: any = {
                      channel:row.order_channel,
                      orderData: row,
                      order_status_code: orderstatus,
                      delivery_status: row.delivery_status,
                      // delivery_time_json: JSON.stringify(deliverytime)
                  }
                  this.props.data.updateOrder(row.order_id, data, this.props.data.history)
              }
          // }
      } else {
          const data: any = {
              channel:row.order_channel,
              order_status_code: orderstatus,
              delivery_status: row.delivery_status,
              orderData: row
          }
          if (orderstatus == row.order_status_code) {
              toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
          } else {
              if (orderstatus == 6) {
                  this.openModal.click();
              } else {
                  this.props.data.updateOrder(row.order_id, data, this.props.data.history)
              }
          }
      }
  }
  isCancelOrderReady = () => {
      const { cancelReason } = this.state;
      return (cancelReason !== "");
  }
  cancelOrder(order_id: any,row:any) {
      const data: any = {
          order_status_code: 6,
          channel:row.order_channel,
          cancel_reason: this.state.cancelReason,
          orderData: row
      }
      if(this.state.otherReason!==""){
        data.cancel_reason_description = this.state.otherReason;
      }
      this.props.data.updateOrder(order_id, data, this.props.data.history);
      this.closeModal.click();
  }
async  handleInputChange (event: { target: { name: any; value: any; }; }) {
    await  this.setState({
          [event.target.name]: event.target.value
      });
  }
  
  render() {
      const { data, row } = this.props;
      const { orderstatus } = this.state;
      let tokenData: any = jwt.decode(sessionStorage.token)
      let roleId = tokenData.role_id;
      return (
          <div>
              {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6 ||row.order_status_code === 10) ? <span {...(((row.order_status_code === 4 || row.order_status_code === 5) && { className: "badge badge-success text-capitalize p-1" }) || ((row.order_status_code === 6  || row.order_status_code === 10) && { className: "badge badge-danger text-capitalize p-1" }))}>{row.order_status_description}</span> :
                  <div>
                      {/* <select
                          disabled={row.brand_id == 1 ? true : false}
                          name="orderstatus"
                          value={orderstatus}
                          onChange={this.handleInputChange}
                          className="text-capitalize">
                          {data.statusList &&
                              data.statusList.map((status: any, index: any) => (
                                  (row.future_status == 1) ?
                                      (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 1) ?
                                          (status.order_status_code === 1 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                          : (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 7) ?
                                              (status.order_status_code == 7 || status.order_status_code == 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                              : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 1) ?
                                                  (status.order_status_code === 6 || status.order_status_code === 1) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option> :
                                                  (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 7) ?
                                                      (status.order_status_code === 7 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                      : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 2) ?
                                                          (row.delivery_status == "Delivery")
                                                              //if order is delivery,so Delivered option will be show instead of picked
                                                              ?
                                                              (status.order_status_code === 6 || status.order_status_code === 2) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                              //else if order is pick up,so Picked option will be show instead of picked
                                                              :
                                                              (status.order_status_code === 6 || status.order_status_code === 2) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option> :
                                                          (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 8) ?
                                                              //then 2.3
                                                              (row.delivery_status == "Delivery")
                                                                  ?
                                                                  (status.order_status_code === 6 || status.order_status_code === 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                  :
                                                                  //else 2.3
                                                                  (status.order_status_code === 6 || status.order_status_code === 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                              :
                                                              (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 3) &&
                                                                  (row.delivery_status == "Delivery")
                                                                  ?
                                                                  (status.order_status_code === 3 ) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Dispatched" : status.order_status_description}</option>
                                                                  :
                                                                  //else 2.3
                                                                  (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready For Pickup" : status.order_status_description}</option>
                                      : 
                                      row.order_status_code == 1 ?
                                          //if order status is 1 so then
                                          (status.order_status_code == 6 || status.order_status_code == 1 ) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                          //if status is not 1 so then
                                          :
                                          //if status is 2
                                          (row.order_status_code == 2) ?
                                              (row.delivery_status == "Delivery")
                                                  //if order is delivery,so Delivered option will be show instead of picked
                                                  ?
                                                  (status.order_status_code == 2 || status.order_status_code == 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                  //else if order is pick up,so Picked option will be show instead of picked
                                                  :
                                                  ( status.order_status_code == 2 || status.order_status_code == 6) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                              //Nested else if order status is 3
                                              : (row.order_status_code == 8) ?
                                                  //then 2.3
                                                  (row.delivery_status == "Delivery")
                                                      ?
                                                      (status.order_status_code === 8 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                      :
                                                      //else 2.3
                                                      (status.order_status_code === 8 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                  : (roleId == 9) ? //if role is supervisor then cancel option is available for dispatched or ready to pickup status 
                                                      //then 2.3
                                                      (row.delivery_status == "Delivery") && (row.order_status_code == 3)
                                                      ?
                                                      (status.order_status_code === 3 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                      :
                                                      //else 2.3
                                                      (status.order_status_code === 3) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                                                      : (row.delivery_status == "Delivery") && (row.order_status_code == 3) // else call center agent will not have cancel options
                                                      ?
                                                      (status.order_status_code === 3) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                      :
                                                      //else 2.3
                                                      (status.order_status_code === 3) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                                                      ))
                          }
                      </select> */}

                       
                          <select
                            disabled={(row.brand_id == 2 || row.order_status_code == 1 ) ? false : true}
                            name="orderstatus"
                            value={orderstatus}
                            onChange={this.handleInputChange}
                            className="text-capitalize">
                            {data.statusList &&
                                data.statusList.map((status: any, index: any) => (
                                    (row.future_status == 1) ?
                                        (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 1 ||  row.order_status_code == 9 || row.order_status_code == 10 ) ?
                                            (status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            : (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 7) ?
                                                (status.order_status_code != 1 && status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 1) ?
                                                    (status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option> :
                                                    (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 7) ?
                                                        (status.order_status_code != 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 2) ?
                                                            (row.delivery_status == "Delivery")
                                                                //if order is delivery,so Delivered option will be show instead of picked
                                                                ?
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                //else if order is pick up,so Picked option will be show instead of picked
                                                                :
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option> :
                                                            (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 8) ?
                                                                //then 2.3
                                                                (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                                :
                                                                (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 3) &&
                                                                    (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Dispatched" : status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready For Pickup" : status.order_status_description}</option>
                                        :
                                        (row.order_status_code == 1 ||  row.order_status_code == 9 || row.order_status_code == 10 ) ?
                                            //if order status is 1 so then
                                            (status.order_status_code != 3 &&  status.order_status_code != 2 && status.order_status_code != 6 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            //if status is not 1 so then
                                            :
                                            //if status is 2
                                            (row.order_status_code == 2) ?
                                                (row.delivery_status == "Delivery")
                                                    //if order is delivery,so Delivered option will be show instead of picked
                                                    ?
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                    //else if order is pick up,so Picked option will be show instead of picked
                                                    :
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                //Nested else if order status is 3
                                                : (row.order_status_code == 8) ?
                                                    //then 2.3
                                                    (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                    : (row.order_status_code == 3) &&
                                                        //then 2.3
                                                        (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                                                               ))
                            }
                        </select>
                      <br />
                      {/* {orderstatus == 6 && <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span>} */}
                      {(row.order_status_code == 1)&&<span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span>}
                  </div>
              }
              <div style={{ display: "none" }}>
                  <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewcancel${row.order_id}`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
              </div>
              <div id={`viewcancel${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                  <div role="document" className="modal-dialog">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h4 id="exampleModalLabel" className="modal-title">Cancel Order</h4>
                              <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                          </div>
                          <div className="modal-body">
                              <div className="row">
                                  <div className="col-12">
                                      <h6>Are you sure,you want to cancel the order?</h6>
                                  </div>
                                  <div className="col-12">
                                      <div className="form-group">
                                          <select
                                              name="cancelReason"
                                              onChange={this.handleInputChange}
                                              className="form-control mt-2">
                                              <option value="">Select Reason</option>
                                              {data.cancelReasons &&
                                                  data.cancelReasons.map((reason: any, index: any) => (
                                                      <option key={index} value={reason.id}>{reason.reason}</option>
                                                  ))
                                              }
                                          </select>

                                      </div>
                                  </div>
                                  <div className="col-12">
                                        <div className="form-group">
                                            <input
                                                id="otherReason"
                                                style={{fontSize:15}}
                                                type="text"
                                                name="otherReason"
                                                placeholder="Description"
                                                className="input-material"
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                               
                              </div>
                              <div className="form-group d-flex justify-content-end mt-4">
                                  <button className='btn btn-primary btn-danger' disabled={!this.isCancelOrderReady()} onClick={() => this.cancelOrder(row.order_id,row)}>Save</button>
                              </div>
                          </div>
                          <div style={{ display: "none" }}>
                              <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      );
  }
}

class DriverEditor extends React.Component<{ data: any, row: any,driver_state:any }, { [x: number]: any, deliveryboy: any }> {
  constructor(props: any) {
      super(props);
      this.state = {
          deliveryboy: ""
      }
      this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
      const { row } = this.props
      this.setState({ deliveryboy: row.delivery_boy })
  }
  handleSave = () => {
      
      const { row } = this.props
      const { deliveryboy } = this.state;
      const data = {
          delivery_boy: deliveryboy ? deliveryboy : "",
      }
      if (deliveryboy !== "" && deliveryboy > 0) {
          this.props.data.updateOrder(row.order_id, data, this.props.data.history,this.props.driver_state.brand_id.value)
      } else {
          this.setState({ deliveryboy: row.delivery_boy })
          toast.error("Please select the driver", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
      }

  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
      this.setState({
          [event.target.name]: event.target.value
      });
  }
  render() {
      const { data, row } = this.props
      const { deliveryboy } = this.state;
      console.log("row.order_channel",row.order_channel)
      return (
        <>
          {(row.order_channel == 'web' || row.order_channel == 'mobile' || row.order_channel == 'callcenter') && <div>
              {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6) ? <p>{row.driver_name}</p> :
                  // (row.order_status_code !== 1 && row.order_status_code !== 2 && row.order_status_code !== 7) &&
                  (
                      <div>
                          <select
                              name="deliveryboy"
                              {...row.delivery_boy != null && {disabled:true}}
                              // {...row.order_status_code == 3 && { disabled: true }}
                              // {...(row.order_status_code == 3 || row.aggregator_delivery == 1) && { disabled: true }}
                              value={deliveryboy || ''}
                              onChange={this.handleInputChange}
                              className="text-capitalize">
                              <option value="">Select Driver</option>
                              {data.delivery_boys &&
                                  data.delivery_boys.map((driver: any, index: any) => (
                                      // To lock local rider after one order dispatched
                                      // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1 && (driver.goPanda == "true" || ((driver.goPanda == "false" && !driver.order_id) || (driver.order_id && driver.order_id == row.order_id)))) ?
                                      // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) ?
                                      (((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) || driver.store_id == null) ?
                                          <option key={index} value={driver.id}>{driver.user_name.length > 15 ? `${driver.user_name.substring(0, 15)}...` : driver.user_name}</option>
                                          : ""
                                  ))
                              }
                          </select>
                          <br />
                          {/* {(row.order_status_code != 3 && row.aggregator_delivery == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>} */}
                          {(row.order_status_code != 3 && row.aggregator_delivery == null && row.delivery_boy == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>}
                      </div>
                  )
              }
          </div>}
          </>
      );
  }
}

const selectStatusEditor = (cell: any, row: any, props: any) => {
  return (
      <StatusEditor row={row} data={props} />
  )
}


function priceFormatter(cell: any,row:any) {
  if(row.aggregator_orderId){
    return `AED. ${cell}`;
}else{
    return `AED. ${Math.round(cell)}`;
}
}
function complaintFormatter(cell: any, row: any, props: any) {
  return (
    <Complaint row={row} data={props} />
  );
}

function brandFormatter(cell: any, row: any, props: any) {
  return (
    <img className="img-fluid rounded-circle" src={API_URL + cell} />
  );
}
function actionFormatter(cell: any, row: any, props: any) {
  console.log("props",props)
  return <ActionFormatter row={row} data={props.props} action_state = {props.state} />;
}

const selectDriverEditor = (cell: any, row: any, props: any) => {
  return (
      row.delivery_status == "Delivery" ?
          <DriverEditor row={row} data={props.props} driver_state = {props.state} /> : ""
  )
}


class order extends Component<OrderProps, OrderState> {
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      days: 0,
      orderId: ''
    }
    this.handleDaysFilter = this.handleDaysFilter.bind(this);
    this.handleTimer = this.handleTimer.bind(this);

  }
  componentDidMount() {
    let days = this.state;
    this.props.ordersList(days);
    this.props.getAgentStats(days);
    this.props.storesList();
    this.props.complaintReasonsList();
    this.props.orderStatusList();
    this.props.cancelOrdersReasons();
    this.props.luanchTimer(1,this.state.days);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.orders.length > 0) {
        var audio = new Audio();
        audio.src = process.env.PUBLIC_URL + "/assets/sound/old-style-phone-ringing.mp3";
        // if (prevOrdersLength < nextProps.orders.length) {
        nextProps.orders.map((element: any) => {
            if (element.future_status == 1) {
              // element.order_status_code == 1 && element.orderStatus == "future" && 
                if (element.delivery_status == 'Delivery' && element.delivery_boy == null) {
                    // audio.play();
                    if(element.order_status_code == 1) {
                      audio.oncanplaythrough = (event) => {
                        var playedPromise = audio.play();
                        if (playedPromise) {
                            playedPromise.catch((e) => {
                                console.log(e)
                                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                    console.log(e.name);
                                }
                            }).then(() => {
                                console.log("playing sound !!!");
                                return
                            });
                        }
                      }
                    }
                    
                    // (element.order_status_code == 1 || element.order_status_code == 7) && element.orderStatus == "now" && 
                } else if (element.delivery_status == 'Delivery' && element.delivery_boy == null) {
                  if(element.order_status_code == 1) {
                      audio.oncanplaythrough = (event) => {
                        var playedPromise = audio.play();
                        if (playedPromise) {
                            playedPromise.catch((e) => {
                                console.log(e)
                                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                    console.log(e.name);
                                }
                            }).then(() => {
                                console.log("playing sound !!!");
                                return
                            });
                        }
                    }
                    }
                    
                }
            } else {
              console.log("element.delivery_status",element.delivery_status,element.delivery_boy)
              // element.order_status_code == 1 && 
                if (element.delivery_status == 'Delivery' && element.delivery_boy == null) {
                    // audio.play();
                    if(element.order_status_code == 1) {
                      audio.oncanplaythrough = (event) => {
                        var playedPromise = audio.play();
                        if (playedPromise) {
                            playedPromise.catch((e) => {
                                console.log(e)
                                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                    console.log(e.name);
                                }
                            }).then(() => {
                                console.log("playing sound !!!");
                                return
                            });
                        }
                      }
                    }
                    

                }
            }
        });
        // }
    }
}
  handleDaysFilter(event: { target: { name: any; value: any; }; }) {
    let obj = { days: event.target.value };
    this.props.getAgentStats(obj);
    this.props.ordersList(obj);

    // console.log("event.target.value",event.target.value);
  }

  handleInput = (event: { target: { name: any; value: any; }; }) => {
    this.setState({ orderId: event.target.value })
  };

  handleTimer() {
    console.log("this.props",this.props)
    if (this.props.timerStatus == false) {
        this.props.luanchTimer(1,this.state.days);
    } else if (this.props.timerStatus == true) {
        this.props.stopTimer();
    }
    this.closeModal.click();
}

  handleSearchOrder = (event: any) => {
    event.preventDefault();
    let { orderId } = this.state;
    this.props.searchOrder(orderId)
  }

  refreshOrders = () => {
    let obj = {
      days: 0
    }
    this.props.ordersList(obj);
  }

  classFormatter = (row:any,ind:any)=>{
    console.log("row",row)
    let disable = true
    if(row.delivery_status == 'Delivery' && row.delivery_boy == null) {
      if(row.order_status_code == 6 ||row.order_status_code == 10) {
        disable = true
      } else {
        disable = false
      }
    }
    return disable == false ? 'pending_status' : 'other_status'
  }

  render() {
    let { orders, agentStats } = this.props;
    let tokenData: any = jwt.decode(sessionStorage.token)
    let roleId = tokenData.role_id;
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Orders Not Found"
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  {roleId === 8 ?
                  <h4 className="mt-2">Agent Dashboard</h4>
                  :<>
                  
                    <h4 className="mt-2">Orders <button title={this.props.timerStatus == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.props.timerStatus == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button> { this.props.timerStatus == true &&
                        !this.props.updateTimer == true &&
                          <ReactTimer />
                    } </h4>
                  </>
                  }
                  
                  <div className="d-flex">
                  {roleId === 8 && 
                    <div className="stats-place">
                      {agentStats && agentStats.dailyTarget && 
                      <strong>Daily Target: {priceunit + agentStats.dailyTarget} </strong>
                      }
                      {agentStats.dailyAchievedTarget &&
                      <strong>Daily Achieved Target: {priceunit + agentStats.dailyAchievedTarget}</strong>
                    }
                    </div>
                  }
                    
                    {/* <button  className="btn mr-3" style={{ padding: '0 15px',background:"rgb(217, 177, 101)" }} onClick={this.refreshOrders} ><i style={{color:"#fff"}} className="fa fa-refresh"></i></button> */}
                    <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                      <option value="0">Today</option>
                      <option value="1">Yesterday</option>
                      <option value="7">7 Days</option>
                      <option value="15">15 Days</option>
                      <option value="30">30 Days</option>
                      <option value="60">60 Days</option>
                      <option value="90">90 Days</option>
                    </select>
                  </div>
                  

                </div>
              </div>
            </header>
            {roleId === 8 && 
            <>
            <section className="dashboard-counts no-padding mt-4">
              <div className="container-fluid">
                <div className="row bg-white p-2">
                  <div className="col">
                    <h6> Calls History </h6>
                  </div>
                </div>
                <div className="row no-padding bg-white has-shadow">
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-orange "><i className="fa fa-phone"></i></div>
                      <div className="title"><span>Total<br />Calls </span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.totalCalls / 100}%`, height: "4px" }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-red"></div>
                        </div>
                      </div>
                      <div className="number"><strong>{agentStats.totalCalls}</strong></div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-green"><i className="fa fa-check"></i></div>
                      <div className="title"><span>Orders<br />Converted</span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.completeCalls / 100}%`, height: "4px" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-orange"></div>
                        </div>
                      </div>
                      <div><strong>{agentStats.completeCalls}</strong></div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center">
                      <div className="icon  bg-blue "><i className="fa fa-clock-o"></i></div>
                      <div className="title"><span>Average<br />Order Time</span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.avgCallTime / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                        </div>
                      </div>
                      <div className="number"><strong>{agentStats.avgCallTime} Mins</strong></div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-red "><i className="fa fa-clock-o"></i></div>
                      <div className="title"><span>Total<br />Order Time</span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.ignoreCalls / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                        </div>
                      </div>
                      <div className="number"><strong>{agentStats.totalCallTime}</strong></div>
                    </div>
                  </div>
                </div>

              </div>
            </section>
            <section className="dashboard-counts no-padding mt-4">
              <div className="container-fluid">
                <div className="row bg-white p-2">
                  <div className="col">
                    <h6>Agent Sales Achievement </h6>
                  </div>
                </div>
                <div className="row no-padding bg-white has-shadow">
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-orange "><i className="fa fa-money"></i></div>
                      <div className="title"><span>Total<br />Sales</span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.totalSales / 100}%`, height: "4px" }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-red"></div>
                        </div>
                      </div>
                      <div className="number"><strong>{agentStats.totalSales}</strong></div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center">
                      <div className="icon bg-green"><i className="fa fa-check"></i></div>
                      <div className="title"><span>Ticket<br />Average</span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.avgTicket / 100}%`, height: "4px" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-orange"></div>
                        </div>
                      </div>
                      <div><strong>{agentStats.avgTicket}</strong></div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6">
                    <div className="item d-flex align-items-center">
                      <div className="icon  bg-blue "><i className="fa fa-envelope"></i></div>
                      <div className="title"><span>Feedback<br />Submitted</span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.avgCallTime / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                        </div>
                      </div>
                      <div className="number"><strong>{agentStats.infoCalls}</strong></div>
                    </div>

                  </div>
                  <div className="col-xl-3 col-sm-6">

                    <div className="item d-flex align-items-center">
                      <div className="icon bg-red "><i className="fa fa-ban"></i></div>
                      <div className="title"><span>Cancelled<br />Orders</span>
                        <div className="progress">
                          <div role="progressbar" style={{ width: `${agentStats.ignoreCalls / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                        </div>
                      </div>
                      <div className="number"><strong>{agentStats.ignoreCalls}</strong></div>
                    </div>

                  </div>
                </div>

              </div>
            </section>
            </>
            }
            {/* <section className="tables mt-4 no-padding">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between py-3">
                          <h4 className="mt-2">Statistics</h4>
                        </div>
                        {agentStats &&
                          <div className="d-flex">

                            <table
                              style={{ width: "50%" }}
                              className="table-bordered"
                            >
                              <tr>
                                <td>Total calls</td>
                                <td>{agentStats.totalCalls}</td>
                                <td>Transferred</td>
                                <td>0</td>
                                <td>Last Phone No</td>
                                <td>Unknown</td>
                              </tr>
                              <tr>
                                <td>Answered</td>
                                <td>{agentStats.completeCalls}</td>
                                <td>Average Call time</td>
                                <td>{agentStats.avgCallTime}</td>
                                <td>Target Period</td>
                                <td>25</td>
                              </tr>
                              <tr>
                                <td>Ignored</td>
                                <td>{agentStats.ignoreCalls}</td>
                                <td>Max Period</td>
                                <td>13.7</td>
                                <td>Idle Time</td>
                                <td>23</td>
                              </tr>
                            </table>
                            <table
                              style={{ width: "50%" }}
                              className="table-bordered"
                            >
                              <tr>
                                <td>Total calls</td>
                                <td>19</td>
                                <td>No. of delivery</td>
                                <td>19</td>
                              </tr>

                              <tr>
                                <td>Average Check</td>
                                <td>775</td>
                                <td>No. TDA</td>
                                <td>17</td>
                              </tr>
                              <tr>
                                <td>Total Sales</td>
                                <td>{agentStats.totalSales}</td>
                                <td></td>
                              </tr>
                            </table>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

{/* 
                                            <td className="service"><img className="img-fluid rounded-circle" src={API_URL + this.props.brand.landing_image} /></td>

*/}
            <section className="tables pt-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-10 col-md-8 col-12">
                            <div className="form-group">
                              <input id="orderId" type="number" value={this.state.orderId} name="orderId" required placeholder="Search by order id" className="input-material" onChange={this.handleInput} />
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4 col-12 float-right mt-1">
                            <div className="form-group">
                              <button style={{background:"rgb(217, 177, 101)"}} onClick={this.handleSearchOrder} disabled={this.state.orderId == ""} className="btn btn-block"><span style={{color:"#fff"}}>Search Order</span></button>
                            </div>
                          </div>
                        </div>
                        <BootstrapTable
                          trClassName={this.classFormatter}
                          version="4"
                          data={orders}
                          search={true}
                          pagination={orders.length > 10 && true}
                          options={options}
                          hover
                        >
                          
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="order_id"
                            csvHeader="Order Id"
                            width='80'
                            dataSort={true}
                            isKey
                          >
                            Order Id
                              </TableHeaderColumn>
                              <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="landing_image"
                            csvHeader="Brand"
                            dataFormat={brandFormatter} 
                            formatExtraData={this.props} 
                            width='100'
                            dataSort={true}
                          >
                            Brand
                              </TableHeaderColumn>
                         <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                            dataField="aggregator_orderId"
                            csvHeader="Vendor Order Id"
                            width='80'
                            dataSort={true}
                            // isKey
                          >
                            Vendor Order Id
                          </TableHeaderColumn>
                          <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10 }} dataField='user_name' dataFormat={selectDriverEditor} formatExtraData={{props:this.props,state:this.state}} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn>
                              <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="action"
                            dataFormat={actionFormatter}
                            formatExtraData={{props:this.props,state:this.state}}
                            width='150'
                            export={false}
                          >
                            Details
                              </TableHeaderColumn>
                              <TableHeaderColumn width='190' thStyle={{ fontSize: 10, whiteSpace: 'unset' }}  dataField='order_status_description' dataFormat={selectStatusEditor} formatExtraData={this.props} >Status</TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="store_name"
                            csvHeader="Store"
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                            width='80'
                            columnTitle
                          >
                            Store
                              </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="delivery_status"
                            csvHeader="Order Type"
                            width='80'
                            columnTitle
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Order Type
                              </TableHeaderColumn>
                              <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataFormat={channelFormatter}
                            csvHeader="Order Channel"
                            width='100'
                            columnTitle
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Order Channel
                              </TableHeaderColumn>
                              {/* <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="agent_name"
                            csvHeader="Agent Name"
                            width='100'
                            columnTitle
                          >
                            Agent Name
                              </TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataField='action' width='150' dataFormat={selectDriverEditor} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataField='action' width='180' dataFormat={selectStatusEditor} formatExtraData={this.props}>Status</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="customer_name"
                            csvHeader="Customer Name"
                            width='80'
                            columnTitle
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Name
                              </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="phone_number"
                            csvHeader="Phone"
                            width='80'
                            columnTitle
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Phone
                              </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="delivery_address"
                            csvHeader="Address"
                            columnTitle
                            width='150'
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Address
                              </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="order_grossprice"
                            width='100'
                            dataFormat={priceFormatter}
                            csvHeader="Price"
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Order Price
                              </TableHeaderColumn>
                              <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="discount"
                            width='80'
                            dataFormat={priceFormatter}
                            csvHeader="Discount"
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Discount
                              </TableHeaderColumn>
                          {/* <TableHeaderColumn width='100' columnTitle>Delivery Zone</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="payment_method"
                            csvHeader="Payment Method"
                            width='80'
                            columnTitle
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Payment Method
                              </TableHeaderColumn>
                              <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="reason"
                            csvHeader="Cancel Reason"
                            width='80'
                            columnTitle
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Cancel Reason
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 11, whiteSpace: 'unset' }} tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }} width='80' dataField='cancel_reason_description' csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
{/* 
                          <TableHeaderColumn
                            thStyle={{ fontSize: 13, whiteSpace: 'unset' }}
                            dataField="order_status_description"
                            csvHeader="Order Status"
                            columnTitle
                          >
                            Order Status
                              </TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="date_created"
                            csvHeader="Order Recieved"
                            dataFormat={dateFormatter}
                            width='80'
                            columnTitle
                            tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }}
                          >
                            Order Recieved
                              </TableHeaderColumn>
                          <TableHeaderColumn thStyle={{ fontSize: 11, whiteSpace: 'unset' }} width='100' tdStyle={{ wordWrap:'break-word',fontSize:'10px', whiteSpace: 'normal' }} dataField='aggregator_orderId' csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                          <TableHeaderColumn  
                          thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                          dataField='action' 
                          width='80' 
                          dataFormat={complaintFormatter} 
                          formatExtraData={this.props} 
                          export={false}>
                            Action
                              </TableHeaderColumn>

                          
                        </BootstrapTable>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>

              <div>
                    <div id="enableTimer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="exampleModalLabel" className="modal-title">Auto Refresh</h4>
                                    <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure,you want to {this.props.timerStatus == false ? "enable" : "disable"} auto refresh?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" ref={el => this.closeModal = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                                    <button onClick={this.handleTimer} className="btn btn-primary">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}
function channelFormatter(cell: any, row: any) {
  if (row.order_channel == 'deliveroo') {
      return `${row.order_channel} (${row.external_orderId})`;
  } else {
      return `${row.order_channel}`;
  }
}
function dateFormatter(cell: any) {
  var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
  DateCreated = DateCreated[1] + " , " + DateCreated[0];
  return (
      <div>
          <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
      </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    orders: state.order.data,
    stores: state.customer.storesList,
    agentStats: state.order.agent_stats,
    Items: state.order.orderItems,
    orderDetail: state.order.order,
    reasonsList: state.customerComplaint.complaintReasonList,
    statusList: state.order.orderStatus,
    cancelReasons: state.order.cancelReasons,
    delivery_boys: state.order.delivery_boys,
    updateTimer: state.order.isTimerUpdate,
    timerStatus: state.order.timerStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    ordersList: (obj: any) => {
      dispatch(ordersList(obj));
    },
    // orderDetail: function (orderId: any) {
    //   dispatch(orderDetail(orderId));
    // },
    getTaxValue: (state_id: any) => {
      dispatch(getTaxValue(state_id))
    },
    getAgentStats: (days: any) => {
      dispatch(getAgentStats(days))
    },
    // customerDetail: (data: any) => {
    //     dispatch(customerDetail(data))
    // },
    // handleCustomerInput: (event: any) => { dispatch(handleCustomerInput(event)) },
    // addCustomer: (data: any) => { dispatch(addCustomer(data)); },
    // validateEmail: (event: any) => { dispatch(validateEmail(event)) },
    // handleAddressInput: (event: any) => { dispatch(handleAddressInput(event)) },
    storesList: function () {
      dispatch(getStores());
    },
    // getTaxValue: (state_id: any) => {
    //     dispatch(getTaxValue(state_id))
    // },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    showorderItems: function (id: any, orders: any,channel:any) {
      dispatch(showorderItems(id, orders,channel))
  },
  complaintReasonsList: function () {
    dispatch(complaintReasonsList());
  },
  orderStatusList: function () {
    dispatch(orderStatusList())
  },
  cancelOrdersReasons: () => {
    dispatch(cancelOrdersReasons())
  },
  updateOrder: function (id: any, data: any, history: any) {
    dispatch(updateOrder(id, data, history))
  },
  luanchTimer: (brand_id:number,days:any) => {
    dispatch(luanchTimer(brand_id,days))
  },
  stopTimer: () => {
      dispatch(stopTimer())
  },
  searchOrder: (orderId: any) => {
    dispatch(searchOrder(orderId))
  }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(order);

import { LOGIN_USER, FORGOT_PASS, EDIT_PROFILE, GET_PROFILE,COUNTRY_LIST } from './userType'
import jwt from 'jsonwebtoken'
import Api from '../../components/Api';

export const loginUser = (email: any, password: any,lat:any,lng:any) => {
    return function (dispatch: any) {
        Api.post('/admin/callCauth', {
            email: email,
            password: password,
            lat:lat,lng:lng
        })
            .then((response) => {
                if (response.data.success) {
                    let role_id = response.data.role_id;
                    let token = response.data.accessToken;
                    let username = response.data.first_name + " " + response.data.last_name
                    sessionStorage.setItem('name', username)
                    sessionStorage.setItem('token', token)
                    console.log(response.data)
                    if(role_id == 8){
                        window.location.href = "/dashboard"
                    }
                    else if(role_id === 7){
                    window.location.href = "/dashboard";
                    }
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    dispatch({
                        type: LOGIN_USER,
                        payload: error,
                        isLoggedIn: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getProfile = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.token;
        let data: any = jwt.decode(token);
        Api.get(`/admin/profile/${data.user_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_PROFILE,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editProfile = (firstname: any, lastname: any, email: any, city: any, country: any, address: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.token;
        let data: any = jwt.decode(token);
        Api.put(`/admin/edit_profile/${data.user_id}`, {
            first_name: firstname,
            last_name: lastname,
            email_address: email,
            city: city,
            country_id: country,
            address: address
        }, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let username = firstname + " " + lastname
                    sessionStorage.setItem('name', username)
                    // dispatch(logoutAdmin());
                    dispatch({
                        type: EDIT_PROFILE,
                        isUpdated: true,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    dispatch({
                        type: EDIT_PROFILE,
                        isUpdated: false,
                        payload: error
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const changePassword = (newPass: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.token;
        let data: any = jwt.decode(token);
        Api.put(`/admin/change_password`, {
            user_id: data.user_id,
            password: newPass
        }, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(logoutUser());
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const forgotPassword = (email: any) => {
    return function (dispatch: any) {
        Api.post('/admin/forgot-password', {
            email: email
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: FORGOT_PASS,
                        payload: "Your password has been updated and We have sent the email on given email address",
                        isReset: true
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    dispatch({
                        type: FORGOT_PASS,
                        payload: error,
                        isReset: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const countryList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/countries', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: COUNTRY_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const logoutUser = () => {
    return function (dispatch: any) {
        sessionStorage.clear();
        window.location.href = "/"
    }
}
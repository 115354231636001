import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import { logoutUser } from "../../redux";
import { AgentProps, AgentState } from "../../interfaces/agent";
import {
  agentsList,
  blockunblockuser,
  delAgent,
} from "../../redux/actions/agentAction";
import { Modal } from "react-responsive-modal";

class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  handleDelete = (id: any) => {
    this.props.data.delAgent(id);
  };
  handleBlockUnblock = (id: any, status: any) => {
    this.props.data.blockunblockuser(id, status,this.props.row.email_address);
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title={row.status == 0 ? "UnBlock" : "Block"}
          data-toggle="modal"
          data-target={`#blockunblock${row.user_id}`}
          className={
            row.status == 0
              ? "btn btn-outline-success mr-2"
              : "btn btn-outline-danger mr-2"
          }
        >
          <i className={row.status == 0 ? "fa fa-unlock" : "fa fa-lock"}></i>
        </button>
        {/* <button title="Delete User" className="btn btn-outline-danger" data-toggle="modal" data-target={`#DelUser${row.user_id}`}><i className="fa fa-trash"></i></button> */}
        <Link
          title="Edit Agent"
          className="btn btn-outline-primary ml-2"
          to={`/edit-agent/${row.user_id}`}
        >
          <i className="fa fa-edit"></i>
        </Link>
        {/* <!-- Modal--> */}
        <div
          id={`DelUser${row.user_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Delete User
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure,you want to delete this user?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() => this.handleDelete(row.user_id)}
                  className="btn btn-danger"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Block/Unblock Modal--> */}
        <div
          id={`blockunblock${row.user_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  {row.status == 0 ? "UnBlock" : "Block"} User
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure,you want to{" "}
                  {row.status == 0 ? "UnBlock" : "Block"} this driver?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() =>
                    this.handleBlockUnblock(row.user_id, row.status)
                  }
                  className="btn btn-danger"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}

function orderModesFormatter(cell: any, row: any, props: any) {
  return <OrderModesFormatter row={row} data={props} />;
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  IsJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  calcPercentage(target:any,sale:any){
    return (sale/target)*100;
  }
  render() {
    const { row } = this.props;
    if(row.performance){
    return (
      <div>
          <button
            title="View Performance"
            data-toggle="modal"
            data-target={`#itemmodes${row.user_id}`}
            className="btn btn-outline-info"
          >
            <i className="fa fa-eye"></i>
          </button>
        {/* <!-- Modal--> */}
        <div
          id={`itemmodes${row.user_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Performance Overview
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                  <h6>Daily Target Sales Vs Daily Achieved Sales</h6>
                    <div className="form-group text-capitalize">
                     <div className='d-flex justify-content-between'>
                       <span>Daily Target</span>
                       <input defaultValue={row.performance.daily_target} disabled/>

                     </div>
                     <div className='d-flex justify-content-between'>
                       <span>Daily Achieved Target</span>
                       <input defaultValue={row.performance.dailySales} disabled/>
                     </div>
                     <div className='d-flex justify-content-between'>
                       <span>Target Completion Percentage</span>
                       <span>{Math.round(this.calcPercentage(row.performance.daily_target,row.performance.dailySales))}%</span>
                     </div>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                  <h6>Weekly Target Sales Vs Weekly Achieved Sales</h6>
                    <div className="form-group text-capitalize">
                     <div className='d-flex justify-content-between'>
                       <span>Weekly Target</span>
                       <input defaultValue={row.performance.weekly_target} disabled/>

                     </div>
                     <div className='d-flex justify-content-between'>
                       <span>Weekly Achieved Target</span>
                       <input defaultValue={row.performance.weeklySales} disabled/>
                     </div>
                     <div className='d-flex justify-content-between'>
                       <span>Target Completion Percentage</span>
                       <span>{Math.round(this.calcPercentage(row.performance.weekly_target,row.performance.weeklySales))}%</span>
                     </div>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                  <h6>Monthly Target Sales Vs Monthly Achieved Sales</h6>
                    <div className="form-group text-capitalize">
                     <div className='d-flex justify-content-between'>
                       <span>Monthly Target</span>
                       <input defaultValue={row.performance.monthly_target} disabled/>

                     </div>
                     <div className='d-flex justify-content-between'>
                       <span>Monthly Achieved Target</span>
                       <input defaultValue={row.performance.monthlySales} disabled/>
                     </div>
                     <div className='d-flex justify-content-between'>
                       <span>Target Completion Percentage</span>
                       <span>{Math.round(this.calcPercentage(row.performance.monthly_target,row.performance.monthlySales))}%</span>
                     </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else{
    return null
  }
  }
}

class Agents extends Component<AgentProps, AgentState> {
  constructor(readonly props: any) {
    super(props);
  }
  componentDidMount() {
    this.props.agentsList();
    document.title = "SimplexCRM | Agents";
  }

  onCloseModal = () => {
    this.setState({ open: false });
  };

  GotoHome = () => {
    window.location.href = "/";
  };
  render() {
    let { agentList } = this.props;
    console.log(agentList);
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }

    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      insertModal: () => {
        return <Redirect to="/add-agent" />;
      },
      noDataText: "Driver Not Found",
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Agents Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          version="4"
                          data={agentList}
                          search={true}
                          options={options}
                          exportCSV={true}
                          // insertRow
                          csvFileName="drivers.csv"
                          hover
                        >
                          <TableHeaderColumn
                            dataField="user_id"
                            csvHeader="#"
                            width="60"
                            dataSort={true}
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="user_name"
                            csvHeader="Name"
                            width="100"
                            columnTitle
                          >
                            Agent Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="email_address"
                            csvHeader="Email"
                            width="100"
                            columnTitle
                          >
                            Email
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="phone_number"
                            csvHeader="Phone Number"
                            width="100"
                            columnTitle
                          >
                            Phone Number
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="city"
                            csvHeader="City"
                            width="100"
                            columnTitle
                          >
                            City
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="address"
                            csvHeader="Address"
                            width="100"
                            columnTitle
                          >
                            Address
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="item_mode"
                            csvHeader="Mode"
                            width="100"
                            dataFormat={orderModesFormatter}
                            columnTitle
                          >
                            View Performance
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            dataField="action"
                            width="180"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  console.log(state.user);
  return {
    agentList: state.agent.agents,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    agentsList: function () {
      dispatch(agentsList());
    },
    blockunblockuser: function (id: any, status: any,email_address:any) {
      dispatch(blockunblockuser(id, status,email_address));
    },
    delAgent: function (id: any) {
      dispatch(delAgent(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agents);

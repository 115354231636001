import { CUSTOMER_DETAIL, CUSTOMER_ORDERS_LIST, SHOW_TIMER, ORDER_DETAIL, STORES_LIST, SELECT_STORE, HANDLE_INPUT, VALIDATE_EMAIL, TIMER_START, ORDER_DATA, ALL_STORES_LIST, ORDER, PAYMENT, ADDRESS_LIST, SELECTED_FROM_SAVED_ADDRESS, SELECTED_ADDRESS} from './customerType'
import Api from '../../components/Api';
import jwt, { decode } from 'jsonwebtoken'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCart } from './cartAction';
import { saveLogs } from './storeAction';
import * as geolib from 'geolib';
import jwt_decode from "jwt-decode";
import moment from "moment";

toast.configure()
export const handleCustomerInput = (event: { target: { name: any; value: any; }; }) => {
  return {
    type: HANDLE_INPUT,
    input: event.target
  }
}
export const validateEmail = (event: { target: { name: any; value: any; }; }) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(event.target.value) === false) {
    return { type: VALIDATE_EMAIL, email: event.target.value, isValidEmail: false }
  } else {
    return { type: VALIDATE_EMAIL, email: event.target.value, isValidEmail: true }
  }
};

export const customerDetail = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem('token');
    let decoded:any = jwt_decode(token)
    if(data.brand_id ==  null || data.brand_id == '' || data.brand_id == undefined) {
      data.brand_id = decoded.brand_id
    }
    Api.post(`call-portal/getCustomer`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CUSTOMER_DETAIL,
            payload: response.data.successResponse
          })
          console.log("Success Response:", response.data.successResponse)
          sessionStorage.setItem("customer", response.data.successResponse.customer_id);
          sessionStorage.setItem('customername', response.data.successResponse.login_name);
          sessionStorage.setItem('email', response.data.successResponse.email_address);
          sessionStorage.setItem('phone', response.data.successResponse.phone_number);
          dispatch(ordersListByCustomer("", response.data.successResponse.customer_id,response.data.successResponse))
          dispatch(addressesListk())
        }
      }).catch(err => {
        if (err.response) {
          dispatch({
            type: CUSTOMER_DETAIL,
            payload: {}
          })
          dispatch({
            type: CUSTOMER_ORDERS_LIST,
            payload: []
          })
          console.log(err.response.data.message)
          toast.warning(err.response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })

        } else {
          alert(err.message)
        }
      });
  }
}
export const addCustomer = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem('token');
    data.user_info = jwt.decode(token);
    Api.post('/call-portal/addGuest', data, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CUSTOMER_DETAIL,
            payload: response.data.successResponse
          })
          sessionStorage.setItem("customer", response.data.successResponse.customer_id);
          sessionStorage.setItem('customername', response.data.successResponse.login_name);
          sessionStorage.setItem('email', response.data.successResponse.email_address);
          sessionStorage.setItem('phone', response.data.successResponse.phone_number);
          toast.error("Customer added successfully", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
        }
        else {
          toast.error(response.data.successResponse)
        }
      }).catch(err => {
        if (err.response) {
          let error;
          if (typeof (err.response.data.message) === "string") {
            error = err.response.data.message;
          } else if (typeof (err.response.data.message) === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.warning(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
        } else {
          toast.warning(err.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
        }
      });
  }
}
export const orderDetail = (order_id: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem('token');
    if (token) {
      Api.get(`/call-portal/order/${order_id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.successResponse)
            // dispatch(ordersListByCustomer(response.data.successResponse.customer_id))

            dispatch({
              type: ORDER_DETAIL,
              payload: response.data.successResponse,
              loading: false
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: ORDER_DETAIL,
              payload: [],
            });
          } else {
            alert(err.message);
          }
        });
    }
  };
};
export const ordersListByCustomer = (order_id?: any, customer_id?: any,data?:any) => {
  return function (dispatch: any) {
    console.log("customer_id", customer_id)
    let token: any = sessionStorage.getItem('token');
    Api.get(`call-portal/orders/${customer_id}`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.success)
          if (order_id !== '') {
            console.log("order_id in customer orders list", order_id, response.data.successResponse)
            let result: any = []
            result.push(response.data.successResponse.find((x: any) => x.order_id == order_id)); //find the order from the orders list
            console.log(result)
            console.log('response.data.successResponse.length',response.data.successResponse.length)
            if (response.data.successResponse.length>0) {
              console.log("obj if")
              let totalRevenue = 0
              response.data.successResponse.map((item:any)=>{
                totalRevenue += item.order_grossprice
              })
              console.log("")
              let obj ={
                totalRevenue:totalRevenue,
                lastOrderDate:moment(response.data.successResponse[0].date_created).local().format('YYYY-MM-DD HH:mm').split(' ')
              }
              console.log("obj",obj)
              data = {...data,...obj}
              console.log(data,'   customer data    ' , response.data.successResponse);
              dispatch({
                type: CUSTOMER_DETAIL,
                payload: data
              })
            }
            dispatch({
              type: CUSTOMER_ORDERS_LIST,
              payload: result
            })
            
          }
          else {
            dispatch({
              type: CUSTOMER_ORDERS_LIST,
              payload: response.data.successResponse
            })
            if (response.data.successResponse.length>0) {
              console.log("obj if")
              let totalRevenue = 0
              response.data.successResponse.map((item:any)=>{
                totalRevenue += item.order_grossprice
              })
              console.log("")
              let obj ={
                totalRevenue:totalRevenue,
                lastOrderDate:moment(response.data.successResponse[0].date_created).local().format('YYYY-MM-DD HH:mm').split(' ')
              }
              console.log("obj",obj)
              data = {...data,...obj}
              console.log(data,'   customer data    ' , response.data.successResponse);
              dispatch({
                type: CUSTOMER_DETAIL,
                payload: data
              })
            }
          }
        }
      }).catch(err => {
        if (err.response) {
          dispatch({
            type: CUSTOMER_ORDERS_LIST,
            payload: []
          })
          console.log(err.response.data.message)
        } else {
          alert(err.message)
        }
      });
  }
}
export const getStores = () => {
  let token:any = sessionStorage.getItem('token');
  let decoded: any = jwt_decode(token)
  return async (dispatch: any) => {
    Api.get('menu/stores/'+decoded.brand_id)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: STORES_LIST,
            payload: response.data.successResponse,
          });
          dispatch({
            type: ALL_STORES_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          alert(error)
        } else {
          alert(err.message)
        }
      });
  };
};

//LocalStore with kml Coordinates Json
export const findLocalStores = (lat: any, lng: any) => {
  let token:any = sessionStorage.getItem('token');
  let decoded: any = jwt_decode(token)
  console.log("finding local stores nearby",lat,lng)
  return async (dispatch: any) => {
    Api.get("menu/stores/"+decoded.brand_id)
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          for (let i = 0; i < Responedata.length; i++) {
            if (Responedata[i].zone_json) {
              console.log("in if")
              if (geolib.isPointInPolygon({ latitude: lat, longitude: lng }, JSON.parse(Responedata[i].zone_json))) {
                data.push(Responedata[i]);
              }
            }
          }
          console.log("data",data)
          //find distance of two coordinates
          dispatch({
            type: STORES_LIST,
            payload: data,
            selectStoreId: data.length > 0 && data[0].store_id,
            deliveryfee: data.length > 0 && data[0].delivery_fee,
            storeStatus: 0,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: STORES_LIST,
            payload: data,
            deliveryfee: 0,
            storeStatus: 0,
          });
        } else {
          alert("Connection Lost");
        }
      });
  };
};

export const saveStore = (store: any) => {
  return function (dispatch: any) {
    if (store) {
      // console.log("store saved action",store.state_id.pos_service_fee)
      dispatch({
        type: SELECT_STORE,
        selectedStore: store,
      });
    } 
    else {
      dispatch({
        type: SELECT_STORE,
        selectedStore: {},
        deliveryfee: ""
      });
    }
  };
};

export const saveOrderid = (order_id: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER,
      order_id: order_id,
    });
  };
};

export const saveOrder = (data: any, store: any, tax: any, callObj: any) => {
  console.log(callObj)
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem('token');
    // let decoded: any = jwt.decode(token);
    data.customer_id = sessionStorage.getItem("customer");
    Api.post(`/customer/save_order`, data)
      .then(async (response) => {
        if (response.data.success) {
          //for invoice email
          if (tax) { response.data.order.tax = tax };
          if (store) {
            response.data.order.store = store.store_name;
            response.data.order.store_address = store.address;
          }
          response.data.order.name = await sessionStorage.getItem('customername');
          response.data.order.email = await sessionStorage.getItem('email');
          response.data.order.phone = await sessionStorage.getItem('phone');
          let obj: any = {
            payment_amount: data.order_grossprice,
            payment_method: 'Cash',
            payment_status: 'pending',
            order_id: response.data.order.order_id,
            orderInfo: response.data.order
          };
          callObj.order_id = response.data.order.order_id;
          // dispatch(paymentProcess(obj))
          dispatch(saveCallrecord(callObj))
          dispatch(saveOrderid(response.data.order.order_id))
          await sessionStorage.removeItem('cart');
          await sessionStorage.removeItem("customer")
          await sessionStorage.removeItem('customername');
          await sessionStorage.removeItem('email');
          await sessionStorage.removeItem('phone');
          dispatch({
            type: PAYMENT,
            PaymentSuccess: "success",
            successFlag: true,
          });
          dispatch(getCart())
          toast.error("Thank You! Your order is successfull submitted", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })

        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.error(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
        } else {
          toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
        }
      });
  };

};

export const saveCallrecord = (data: any) => {

  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem('token');
    let decoded: any = jwt.decode(token);
    console.log(decoded)
    data.user_id = decoded.user_id;
    console.log(data)
    Api.post(`call-portal/add_agent_history`, data, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.success)

        }
      }).catch(err => {
        if (err.response) {
          console.log(err.response.data.message)
        } else {
          alert(err.message)
        }
      });
  }
}

export const paymentProcess = (data: any) => {
  return async (dispatch: any) => {
    let GuestName = await sessionStorage.getItem('guestname');
    Api.post(`/customer/save_payment`, data)
      .then(async (response) => {
        if (response.data.success) {
          await sessionStorage.removeItem('cart');
          await sessionStorage.removeItem("customer")
          await sessionStorage.removeItem('customername');
          await sessionStorage.removeItem('email');
          await sessionStorage.removeItem('phone');
          dispatch({
            type: PAYMENT,
            PaymentSuccess: "success",
            successFlag: true,
          });
          dispatch(getCart())
          toast.error("Thank You! Your order is successfull submitted", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
          // window.location.href="/customerDetail"
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          toast.warning(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
        } else {
          toast.warning(err.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
        }
      });
  };
};

export const submitComplaint = (data: any, callStatsObj: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    Api.post(`/customer/feedback_application`, data)
      .then((response) => {
        if (response.data.success) {
          let userInfo: any = jwt.decode(token)
          let logData: any = {
            role: userInfo.role,
            user_name: userInfo.first_name + userInfo.last_name,
            reason: "Register Feedback",
            activity_type: 'Feedbacks',
            activity_name: `order (${data.receipt_number})`,
            status: "Submit Feedback"
          }
          dispatch(saveLogs(logData))
          setTimeout(() => {
            window.location.href = "/customerDetail"
          }, 1000)
          toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 6000 });
          dispatch(saveCallrecord(callStatsObj))
        }
      }).catch(err => {
        if (err.response) {
          let error;
          if (typeof (err.response.data) === "string") {
            error = err.response.data;
          } else if (typeof (err.response.data) === "object") {
            error = err.response.data;
          }
          console.log(error)
        }
      });
  }
}

// k2g trackOrder

export const trackOrder = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem('token');
    try {
      Api.get(`call-portal/order/${data.order_id}`, {
        headers: { 'Authorization': 'Bearer ' + token }
      })
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            // dispatch({
            //   type: ORDER_DATA,
            //   storeData: responseData
            // })
            dispatch(ordersListByCustomer(data.order_id, response.data.successResponse.order.customer_id))

            // toast.error("Email send successfully", {
            //   position: toast.POSITION.TOP_CENTER,
            //   hideProgressBar: true,
            //   autoClose: 3000,
            // });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: CUSTOMER_ORDERS_LIST,
              payload: []
            })
            toast.warning("Sorry, the order could not be found. Please contact us if you are having difficulty finding your order details.", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            // console.log("NoSignUp");
          }
        });
    } catch (e) {
      // console.log("NoSignUp", e);
    }
  };
};

export const stopCount = () => {
  return function (dispatch: any) {
    dispatch({
      type: SHOW_TIMER,
      showCount: true
    });
  };
}


export const TimerStart = (IsActive: any) => {
  console.log("changing timer status", IsActive)
  return function (dispatch: any) {
    dispatch({
      type: TIMER_START,
      isActive: IsActive,
    });
  };
};

//add user address 
export const addAddressUser = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    if (token) {
      let body = {
        data: data,
        user_info: jwt_decode(token)
      }
      Api.post(`/call-portal/save_addresses`, body, {
        headers: { Authorization: 'Bearer ' + token },
      })
        .then((response) => {
          if (response.data.success) {
            toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
            dispatch(addressesListk())
            // window.location.reload();
          }
        }).catch(err => {
          if (err.response) {
            let error;
            if (typeof (err.response.data) === "string") {
              error = err.response.data;
            } else if (typeof (err.response.data) === "object") {
              error = err.response.data;
            }
          }
        });
    }
  }
}

// after login addres get of user
export const addressesListk = () => {
  console.log("fetch address")
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let customer_id = sessionStorage.getItem('customer')
    if (token) {
      var decoded: any = jwt_decode(token);
      Api.get(`/customer/address/${customer_id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.successResponse)
            dispatch({
              type: ADDRESS_LIST,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: ADDRESS_LIST,
              payload: [],
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

export const isSavedAddressSelected = (value:any,address:any) => {
  console.log("fff 2",address)
  return async(dispatch:any) => {
    dispatch({
      type: SELECTED_FROM_SAVED_ADDRESS,
      payload: value,
    });

    dispatch({
      type: SELECTED_ADDRESS,
      payload: address,
    });
  }
}


import Api from '../../components/Api';
import jwt, { decode } from 'jsonwebtoken'
import { AGENT_STATS, CANCEL_ORDER_REASONS, DELIVERY_BOYS, ORDERS_LIST, ORDER_ITEMS_LIST, ORDER_STATUS_LIST, TIMER_STATUS, UPDATE_ORDER, UPDATE_TIMER } from './orderType';
import { toast } from 'react-toastify';
import moment from 'moment';
let timer: any;
export const ordersList = (obj:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token)
        obj.brand_id = decoded.brand.brand_id
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })
        Api.post('/call-portal/orders',obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })

                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse
                    })
                    dispatch(deliveryBoysList())
                    sessionStorage.setItem('prevLength', response.data.successResponse.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: ORDERS_LIST,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
                dispatch({
                    type: UPDATE_TIMER,
                    isTimerUpdate: false
                })
            });
    }
}

export const deliveryBoysList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/Drivers/getActiveDrivers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((driversData) => {
                if (driversData.data.success) {
                    // To lock local rider after one order dispatched
                    // driversData.data.successResponse.map((obj: any) => {
                    //     if (obj.goPanda == "false") {
                    //         orders.map((order: any) => {
                    //             if (obj.id == order.delivery_boy && (order.order_status_code == 1 || order.order_status_code == 2 || order.order_status_code == 3 || order.order_status_code == 8)) {
                    //                 obj.order_id = order.order_id;
                    //             }
                    //         })
                    //     }
                    // })
                    dispatch({
                        type: DELIVERY_BOYS,
                        payload: driversData.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const orderStatusList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/order_status', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let cancelIndex = response.data.successResponse.findIndex((obj:any) => obj.order_status_code == 6)
                    let cancelObj = response.data.successResponse[cancelIndex]
                    let OrganizedStatuses = response.data.successResponse.filter((item:any) => item.order_status_code !== 6)
                    OrganizedStatuses.push(cancelObj);
                    console.log("Oraganized element", OrganizedStatuses,"sadasd",cancelObj)
                    dispatch({
                        type: ORDER_STATUS_LIST,
                        payload: OrganizedStatuses
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const cancelOrdersReasons = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'cancel order'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CANCEL_ORDER_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_ORDER,
            payload: true
        })
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt.decode(token);
        let decoded:any = jwt.decode(token)
        Api.put(`/admin/update_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    if (data.delivery_boy) {
                        toast.info("Driver Saved Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    } else {
                        if (response.data.error == false) {
                            toast.error(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        } else {
                            toast.info(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        }
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    }
                    let obj = {
                        days: 0,
                        brand_id:decoded.brand_id.brand_id
                    }
                    dispatch(ordersList(obj))
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please select the driver";
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: UPDATE_ORDER,
                        payload: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

export const luanchTimer = (brand_id:number,days:any) => {
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: true
        })
        timer = setInterval(() => {
            let obj = { days: days,brand_id:brand_id }
            dispatch(ordersList(obj));
        }, 30000);
    }
}

export const stopTimer = () => {
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: false
        });
        clearInterval(timer)
    }
}

export const searchOrder = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/search_order/${orderId}`,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}


export const getAgentStats = (days:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let decoded: any = jwt.decode(token);
        console.log("calling decode",decoded)
        
        Api.post(`call-portal/agent_stats/${decoded.user_id}`,days, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
    console.log("Calling for agent stats", response.data.success)
                
                if (response.data.success) {
                    dispatch({
                        type: AGENT_STATS,
                        payload: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const showorderItems = (order_id: any, orders: any,channel?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (order_id === "") {
            dispatch({
                type: ORDER_ITEMS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/order_items/${order_id}/${channel}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        let order = orders.find((obj: any) => {
                            return obj.order_id == order_id;
                        })
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: response.data.successResponse,
                            orderDetail: order
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}

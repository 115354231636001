import React, { Component } from 'react';
import _ from 'lodash'
import './orderDetail.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL, priceunit } from '../../client-config';
toast.configure()

class OrderDetail extends Component<{ data: any, orderId: any, tax: any,brand:any }, {}> {
    calcSubTotalTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        return sum;
    };
    calcTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        return Math.round(sum);
    };
    handleAdd = async (orderData: any) => {
        let cart: any = [];
        this.props.data.getCart();
        let data: any = this.props.data.cart;
        if (!_.isEmpty(data)) {
            cart = JSON.parse(data);
        }
        if (!_.isEmpty(orderData)) {
            orderData.orderItems.forEach((orderItem: any) => {
                console.log(orderItem)
                if (orderItem.menu_item_id !== null) {
                    //if Item is menu_item
                    if (!_.isEmpty(cart)) {
                        let found: any = '';
                        
                        if (found == '') {
                            let size: any = orderItem.item_size && JSON.parse(orderItem.item_size);
                            let modifiers: any = orderItem.modifiers ? JSON.parse(orderItem.modifiers) : [];
                            let totalItemPrice = 0;
                            let subTotal = 0;
                            
                            const itemcart = {
                                item: orderItem,
                                quantity: orderItem.order_item_quantity,
                                selectedsize: size,
                                totalItemPrice: orderItem.order_item_grossprice,
                                actualPrice: Math.round(size.price) + subTotal,
                                subTotal: (Math.round(size.price) + subTotal) * orderItem.order_item_quantity,
                                image: orderItem.image_url
                            };
                            // console.log(itemcart)
                            cart.push(itemcart);
                        }
                    } else {
                        let size: any = orderItem.item_size && JSON.parse(orderItem.item_size);
                        let modifiers: any = orderItem.modifiers
                            ? JSON.parse(orderItem.modifiers)
                            : [];
                        let totalItemPrice = 0;
                        let subTotal = 0;
                        if (modifiers.length > 0) {
                            modifiers.forEach((element: any) => {
                                totalItemPrice += element.modifier_sale_price;
                                subTotal += element.modifier_sale_price;
                            });
                        }
                        const itemcart = {
                            item: orderItem,
                            quantity: orderItem.order_item_quantity,
                            selectedsize: size,
                            totalItemPrice: orderItem.order_item_grossprice,
                            actualPrice: Math.round(size.price) + subTotal,
                            subTotal: (Math.round(size.price) + subTotal) * orderItem.order_item_quantity,
                            image: orderItem.image_url,
                        };
                        // console.log(itemcart)
                        cart.push(itemcart);
                    }
                } else if (orderItem.combo_id) {
                    //if Item is combo item
                    if (!_.isEmpty(cart)) {
                        let found: any = '';
                        if (found == '') {
                            console.log(orderItem)
                            const itemcart = {
                                combo: orderItem,
                                quantity: orderItem.order_item_quantity,
                                price: orderItem.combo_mrp_price,
                                totalItemPrice: orderItem.order_item_grossprice,
                                actualPrice: orderItem.combo_sales_price,
                                subTotal: orderItem.combo_sales_price * orderItem.order_item_quantity,
                                image: orderItem.image_url,
                            };
                            cart.push(itemcart);
                        }
                    } else {
                        const itemcart = {
                            combo: orderItem,
                            quantity: orderItem.order_item_quantity,
                            price: orderItem.combo_mrp_price,
                            totalItemPrice: orderItem.order_item_grossprice,
                            actualPrice: orderItem.combo_sales_price,
                            subTotal: orderItem.combo_sales_price * orderItem.order_item_quantity,
                            image: orderItem.image_url,
                        };
                        cart.push(itemcart);
                    }
                } else {
                    console.log('error');
                }
            });
        }
        this.props.data.saveCart(cart);
        this.props.data.getCart();
        toast.error('Order added to cart', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
    };
    priceFormater = (order:any) => {
        if(order.aggregator_orderId) {
            return order.order_grossprice
        }
        else {
            return Math.round(order.order_grossprice)
        }
    }
    render() {
        let { data, orderId, tax } = this.props;
        let order: any = data.orders.find((obj: any) => {
            return obj.order_id == orderId;
        })
        return (
            <div>
                <div className="d-flex justify-content-end ">
                    <button title="Repeat Order" className="btn btn-outline-primary ml-2" onClick={() => this.handleAdd(this.props.data.orderdata)}>Repeat <i className="fa fa-repeat"></i></button>
                </div>
                <div>
                    <header className="clearfix">
                        {/* <div id="logo">
                            <img className="rounded-circle" src={process.env.PUBLIC_URL + "/assets/img/blue-logo.jpg"} />
                        </div> */}
                        <h1 className="invoiceh1"><b>ORDER #{orderId}</b></h1>
                        <h1 className="invoiceh1"><b>BRAND</b></h1>
                        <div id="project">
                            <h3>Customer Information</h3>
                            <div><span>Name</span> {order && order.login_name}</div>
                            {(order && order.phone_number) && <div><span>Phone</span> {order.phone_number}</div>}
                            <div><span>Store</span>{order && order.store_name}</div>
                            <div><span>From</span>{order && order.address}</div>
                            {(order && order.delivery_address) && <div><span>To </span> {order.delivery_address}</div>}
                            <div><span>Date</span> {order && order.date_created}</div>
                        </div>
                        <div id="secProject">
                            <div id="project2">
                                <div><h5>Payment Mode</h5>{order && order.payment_method}</div>
                            </div>
                            <div id="project3">
                                <div>
                                    <h5>Ordering Channel</h5>{order && order.delivery_status}
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                        <table>
                            <thead>
                                <tr>
                                    <th className="service"></th>
                                    <th className="desc">DESCRIPTION</th>
                                    <th>PRICE</th>
                                    <th>QTY</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.orderdata&&data.orderdata.orderItems && data.orderdata.orderItems.map((obj: any, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td className="service">{index + 1}</td>
                                            <td className="service"><img className="img-fluid rounded-circle" src={API_URL + this.props.brand.landing_image} /></td>
                                            <td className="desc" title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}>{(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}</td>
                                            <td className="unit">{priceunit + Math.round(obj.order_item_grossprice / obj.order_item_quantity)}</td>
                                            <td className="qty">{obj.order_item_quantity}</td>
                                            <td className="total">{priceunit + obj.order_item_grossprice}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={4}>SUBTOTAL</td>
                                    <td className="total">{(data.orderdata && data.orderdata.orderItems && data.orderdata.orderItems.length > 0) && this.calcSubTotalTotal(data.orderdata.orderItems)} PKR</td>
                                </tr>
                                {(order && order.delivery_status == "Delivery") && <tr>
                                    <td colSpan={4}>Delivery Fee</td>
                                    <td className="total">{order && priceunit+ order.delivery_fee}</td>
                                </tr>}

                                <tr>
                                    <td colSpan={4}>Tax</td>
                                    <td className="total">{priceunit + Math.round(order.tax_amount)}</td>
                                </tr>
                                {   (order.discount &&
                                    order.discount > 0) ?
                                    <tr>
                                        <td colSpan={4} className="discountStyle">Your Discount</td>
                                        <td className="discountStyle">{priceunit} {Math.round(order.discount)} </td>
                                    </tr>
                                    :
                                    null
                                }
                                <tr>
                                    <td colSpan={4} className="grand total">GRAND TOTAL</td>
                                    <td className="grand total">{priceunit } {this.priceFormater(order)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div id="notices">
                            <div>NOTICE:</div>
                            <div className="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
                        </div> */}
                    </main>
                </div>
                <footer className="invoicefooter">
                    "Thank you for your business" and Give us feedback at <a href="survey.simplexts.net">survey.simplexts.net</a>
                </footer>
            </div>
        )
    }
}
export default OrderDetail;
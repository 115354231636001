import { AGENTS_LIST, GET_AGENT } from '../actions/agentType'
import { BRANDS, SELECTED_BRAND } from '../actions/brandType'
const initialState: any = {
    brands:[] || '',
    selected_brand:null
}

const brandReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case BRANDS: return {
            ...state,
            brands: action.payload
        }
        case SELECTED_BRAND: return {
            ... state,
            selected_brand:action.payload
        }
        default: return state;
    }
}
export default brandReducer;
import React, { Component } from 'react';
import { start } from 'repl';
class Clock extends React.Component<{ time: any }, {}> {
  format(time: any) {
    let seconds: any = time % 60;
    let minutes: any = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ':' + seconds;
  }
  render() {
    const { time, } = this.props;
    return (
      <div className="displayedTime">
        <h1>{this.format(time)} </h1>
      </div> 
    )
  }
}

class Input extends React.Component<{ onSetCountdown: any }, {}> {

  onSubmit(event: any) {
    event.preventDefault();
    //   const strSeconds = this.refs.seconds.value;
    //   if(strSeconds.match(/[0-9]/)) {
    //     this.refs.seconds.value = '';
    //     this.props.onSetCountdown(parseInt(strSeconds, 10));
    //   }
  }

  render() {
    return (
      <form ref="form" onSubmit={this.onSubmit.bind(this)}>
        <input type="text" ref="seconds" placeholder="enter time in seconds" />
        <input type="submit" value="Start"></input>
      </form>
    )
  }
}

class Button extends React.Component<{ label: any, onClickHandler: any }, {}> {
  render() {
    return (
      <button onClick={this.props.onClickHandler}>{this.props.label}</button>
    );
  }
}

export default class App extends React.Component<{time:any,}, { count: any, running: any }> {
  timer: any;
  constructor(props: any) {
    super(props);
    this.state = {
      count: 0,
      running: false
    }
  }
 componentDidMount(){
  //  this.handleStart()
 }
  // componentDidUpdate(prevProps:any, prevState:any) {
  //   if(this.props.start !== prevProps.start){
  //     this.handleStart();
  //   }
  // }

  handleStart() {
    let { time } = this.props;
    this.timer = setInterval(() => {
      const newCount = this.state.count + 1;
      this.setState(
        { count: newCount >= 0 ? newCount : 0 }
      );
    }, 1000);
  }

  handleStop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState(
        { running: false }
      );
    }
  }

  handleReset() {
    this.setState(
      { count: 0 }
    );
  }

  handleCountdown(seconds: any) {
    this.setState({
      count: seconds,
      running: true
    })
  }

  render() {
    const { count } = this.state;
    const { time } = this.props;
    return (
      <div className="container">
      {time && 
        <Clock time={time} />
      }
        {/* <Input onSetCountdown={(seconds)=>{}}/> */}
        {/* <Button label="stop" onClickHandler={this.handleStop.bind(this)}/>
          <Button label="reset" onClickHandler={this.handleReset.bind(this)}/> */}
      </div>
    )
  }
}
import { createStore, applyMiddleware, combineReducers } from 'redux'
import userReducer from './reducers/userReducer'
import customerReducer from './reducers/customerReducer'
import homeReducer from './reducers/homeReducer'
import cartReducer from './reducers/cartReducer';
import menuReducer from './reducers/menuReducer';
import orderReducer from './reducers/orderReducer';
import customerComplaintReducer from './reducers/customerComplaintReducer';
import storeReducer from './reducers/storeReducer';
import agentReducer from './reducers/agentReducer';
import settingsReducer from './reducers/settingsReducer';
import trade_zones_areas_Reducer from './reducers/trade_zones_areas_Reducer';
import brandReducer from './reducers/brandReducer';

const thunkMiddleware = require('redux-thunk').default;

const mainReducer = combineReducers({
    home: homeReducer,
    customer: customerReducer,
    menu: menuReducer,
    brand: brandReducer,
    user: userReducer,
    cart: cartReducer,
    order: orderReducer,
    store: storeReducer,
    customerComplaint: customerComplaintReducer,
    agent: agentReducer,
    settings: settingsReducer,
    trade_zones_areas:trade_zones_areas_Reducer
})
const store = createStore(mainReducer, applyMiddleware(thunkMiddleware))

export default store;
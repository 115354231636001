import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Chart from 'chart.js'
import Topbar from '../components/topbar'
import Sidebar from '../components/sidebar'
import Footer from '../components/footer/main'
import { secretKey } from '../secret'
import { connect } from 'react-redux'
import { logoutUser, homeCounter, recentOrders, monthlyOrdersForLine, monthlySalesForBar, recentItems, customersList } from '../redux'
import { HomeProps } from '../interfaces/home';

class BarChart extends Component<{ data: any[] }, {}> {
    constructor(readonly props: any) {
        super(props)
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let totalSales = []
        for (let i = 0; i < nextProps.data.length; i++) {
            let YM = nextProps.data[i].YM.split('-')
            let month = --YM[1];
            for (let j = --month; j >= 0; j--) {
                if (month === j) {
                    totalSales[j] = nextProps.data[i].totalSales
                } else {
                    totalSales[j] = 0
                }
            }
        }
        var BARCHART: any = document.getElementById('barChart');
        new Chart(BARCHART, {
            type: 'bar',
            options:
            {
                scales:
                {
                    xAxes: [{
                        display: false
                    }],
                    yAxes: [{
                        display: false
                    }],
                },
                legend: {
                    display: false
                }
            },
            data: {
                labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "November", "December"],
                datasets: [
                    {
                        label: "Sales",
                        backgroundColor: [
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)'
                        ],
                        borderColor: [
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)',
                            'rgb(32,165,214)'
                        ],
                        borderWidth: 1,
                        data: totalSales
                    }
                ]
            }
        });
    }
    render() {
        return <canvas id="barChart" />;
    }
}


class LineChart extends Component<{ data: any[] }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let completeOrders = []
        let cancelOrders = []
        for (let i = 0; i < nextProps.data.length; i++) {
            let YM = nextProps.data[i].YM.split('-')
            let month = YM[1];
            for (let j = --month; j >= 0; j--) {
                if (month === j) {
                    completeOrders[j] = nextProps.data[i].completeOrders
                    cancelOrders[j] = nextProps.data[i].cancelOrders
                } else {
                    completeOrders[j] = 0
                    cancelOrders[j] = 0
                }
            }
        }
        let LINECHART: any = document.getElementById('linechart');

        new Chart(LINECHART, {
            type: 'line',
            options: {
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: false
                        }
                    }]
                }
            },
            data: {
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [
                    {
                        label: "Order Cancelled",
                        fill: true,
                        lineTension: 0,
                        backgroundColor: "transparent",
                        borderColor: '#f15765',
                        pointBorderColor: '#da4c59',
                        pointHoverBackgroundColor: '#da4c59',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        borderWidth: 1,
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBorderColor: "#fff",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 0,
                        data: cancelOrders,
                        spanGaps: false
                    },
                    {
                        label: "Order Completed",
                        fill: true,
                        lineTension: 0,
                        backgroundColor: "transparent",
                        borderColor: "#20A5D6",
                        pointHoverBackgroundColor: "#44c384",
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        borderWidth: 1,
                        pointBorderColor: "#44c384",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBorderColor: "#fff",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: completeOrders,
                        spanGaps: false,
                    }
                ]
            }
        });
    }
    render() {
        return <canvas id="linechart" />;
    }
}


class Home extends Component<HomeProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.homeCounter();
        this.props.monthlyOrdersForLine();
        this.props.monthlySalesForBar();
        this.props.recentOrders();
        // this.props.recentItems();
        this.props.customersList();
        document.title = "SimplexCRM | Dashboard"
    }
    render() {
        let roleId: any = ""
        const { counter, monthlyOrders, monthlySales, paymentData, orderData, itemsData, customers } = this.props;
        console.log(itemsData)
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Dashboard</h4>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Dashboard Counts Section--> */}
                        <section className="dashboard-counts no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            {(roleId === 1 || roleId === 3 || roleId === 4 || roleId === 7) ?
                                                <Link to="/orders">
                                                    <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                                </Link>
                                                : <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            }
                                            <div className="title"><span>Total<br />Orders</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.totalOrders / 100}%`, height: "4px" }} className="progress-bar bg-red"></div>   {/*ariaValuenow="70" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            <div className="number"><strong>{counter.totalOrders}</strong></div>
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            {(roleId === 1 || roleId === 2 || roleId === 7) ?
                                                <Link to="/orders">
                                                    <div className="icon bg-orange"><i className="fa fa-shopping-cart"></i></div>
                                                </Link>
                                                : <div className="icon bg-orange"><i className="fa fa-shopping-cart"></i></div>
                                            }
                                            <div className="title"><span>Total<br />Sales</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.totalSales / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            <div><strong>{counter.totalSales}</strong></div>
                                        </div>
                                    </div>


                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            {(roleId === 1 || roleId === 4 || roleId === 7) ?
                                                <Link to="/payments">
                                                    <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                                </Link>
                                                : <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            }
                                            <div className="title"><span>Total<br />Revenue</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.totalRevenue / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            <div className="number"><strong>{counter.totalRevenue}</strong></div>
                                        </div>

                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            {(roleId === 1 || roleId === 4 || roleId === 7) ?
                                                <Link to="/payments">
                                                    <div className="icon bg-blue"><i className="fa fa-ticket"></i></div>
                                                </Link>
                                                : <div className="icon bg-blue"><i className="fa fa-ticket"></i></div>
                                            }
                                            <div className="title"><span>Average<br />Ticket</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.avgTicket / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            <div className="number"><strong>{counter.avgTicket}</strong></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- Dashboard Header Section    --> */}
                        <section className="dashboard-header no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- Statistics --> */}
                                    <div className="statistics col-lg-3 col-12">
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-orange"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.processingOrders}</strong><br /><small>Orders Processing </small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-green"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.completeOrders}</strong><br /><small>Order Completed</small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.cancelOrders}</strong><br /><small>Orders Cancelled</small></div>
                                        </div>
                                    </div>
                                    {/* <!-- Line Chart --> */}
                                    <div className="chart col-lg-6 col-12">
                                        <div className="line-chart bg-white d-flex align-items-center justify-content-center has-shadow">
                                            <LineChart data={monthlyOrders} />
                                        </div>
                                    </div>
                                    <div className="chart col-lg-3 col-12">

                                        {/* <!-- Bar Chart   --> */}
                                        <div className="bar-chart has-shadow bg-white">
                                            <div className="title"><strong>{counter.totalSales / 100}%</strong><br /><small>Total Monthly Sales</small></div>
                                            <BarChart data={monthlySales} />
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-green"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.totalTransaction}</strong><br /><small>Total Payments</small></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <section className="dashboard-counts no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow">
                                    {/* <!-- Item --> */}
                                    <div className="col-4 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="statistic d-flex align-items-center">
                                                <div className="icon bg-green"><i className="fa fa-dollar"></i></div>
                                                <div className="text"><strong>{counter.salesOfDay}</strong><br /><small>Daily Sales</small></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-4 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="statistic d-flex align-items-center">
                                                <div className="icon bg-green"><i className="fa fa-dollar"></i></div>
                                                <div className="text"><strong>{counter.salesOfWeek}</strong><br /><small>Weekly Sales</small></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-4 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="statistic d-flex align-items-center">
                                                <div className="icon bg-green"><i className="fa fa-dollar"></i></div>
                                                <div className="text"><strong>{counter.salesOfMonth}</strong><br /><small>Monthly Sales</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <br />
                        {/* <!-- Feeds Section--> */}
                        <section className="feeds no-padding-top no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- Latest Orders--> */}
                                    <div className="col-lg-7">
                                        <div className="articles card">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <strong>Latest Orders</strong>
                                                {(roleId === 1 || roleId === 3 || roleId === 4 || roleId === 7) &&
                                                    <ul className="mb-0">
                                                        <Link to="/orders"><button className="btn btn-primary p-1">View All Orders</button></Link>
                                                    </ul>
                                                }
                                            </div>
                                            <div className="card-body">
                                                {orderData.length > 0 ? <div className="table-responsive">
                                                    <table className="table table-striped table-hover m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Order</th>
                                                                <th>Customer</th>
                                                                <th>Phone</th>
                                                                <th>Payment Method</th>
                                                                <th>Price</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orderData.map((data: any) => (
                                                                <tr key={data.order_id}>
                                                                    <td>{data.order_id}</td>
                                                                    <td>{data.login_name}</td>
                                                                    <td>{data.phone_number}</td>
                                                                    <td>{data.payment_method}</td>
                                                                    <td>{(data.aggregator_orderId && data.aggregator_orderId > 0) ?
                                                                        data.order_grossprice : Math.round(data.order_grossprice)
                                                                    }</td>
                                                                    <td className="text-capitalize"><span {...(data.order_status_code === 1 && { className: "badge badge-primary p-1" }) || (data.order_status_code === 2 && { className: "badge badge-info p-1" }) || (data.order_status_code === 3 && { className: "badge badge-secondary p-1" }) || (data.order_status_code === 4 && { className: "badge badge-success p-1" }) || (data.order_status_code === 5 && { className: "badge badge-danger p-1" })}>{data.order_status_description}</span></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> : <p className="text-center my-3">No Records Found</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5">
                                        <div className="articles card">
                                            <div className="card-header d-flex align-items-center">
                                                <h2 className="h3">Top Customers   </h2>

                                                <div className="badge badge-rounded bg-green">
                                                    5 new
                        </div>

                                            </div>
                                            {customers.map((object: any, index: any) => (
                                                <div className="card-body no-padding">
                                                    <div className="item d-flex align-items-center">
                                                        <div className="image"><img
                                                            src={object.profile_pic == "" ? process.env.PUBLIC_URL + "/assets/img/pic1.png" : `https://yum.simplexts.net:3000/${object.profile_pic}`}
                                                            alt="" className="img-fluid rounded-circle"
                                                            width="50px"
                                                            height="55px"
                                                        /></div>
                                                        <div className="text"><a href="#">
                                                            <h3 className="h5">{object.first_name}{object.last_name}</h3>
                                                        </a>
                                                            <small>{object.phone_number} </small>
                                                            <br />
                                                            <small>{object.email_address} </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>


                                    {/* <!-- Check List --> */}
                                    {/*<div classNameName="col-lg-5">
                                        <div className="daily-feeds card">
                                            <div className="card-header">
                                                <strong>Top Customers</strong>
                                            </div>
                                            <div className="item d-flex align-items-center">
                                    
                                                {customers.map((object : any, index : any) => (
                                                    <div className="item d-flex align-items-center">
                                                      <div className="image"><img src={`https://yum.simplexts.net:3000/${customers.profile_pic}`} alt="..." className="img-fluid rounded-circle"/></div>
                                                      <div className="text"><a href="#">
                                                <h3 className="h5">{customers.first_name }{customers.last_name }</h3></a><small><br/>Posted on 5th June 2017 by Aria Smith.   </small>
                                                        </div>
                                                    <br/>
                                                    </div>
                                                
                                                ))}                                             
                                            </div>   
                                        </div>
                                    </div>
                                    */}
                                    {/*<iframe src="https://www.easymapmaker.com/map/a0016927de8d617214c4d1fa12c2b510" height="400" width="400" title="Iframe Example"></iframe>*/}
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        monthlyOrders: state.home.monthlyOrders,
        monthlySales: state.home.monthlySales,
        counter: state.home.counter,
        paymentData: state.home.paymentData,
        orderData: state.home.orderData,
        itemsData: state.home.itemsData,
        customers: state.home.customerData
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        homeCounter: function () {
            dispatch(homeCounter())
        },
        monthlyOrdersForLine: function () {
            dispatch(monthlyOrdersForLine())
        },
        monthlySalesForBar: function () {
            dispatch(monthlySalesForBar())
        },
        recentOrders: function () {
            dispatch(recentOrders())
        },
        recentItems: function () {
            dispatch(recentItems())
        },
        customersList: function () {
            dispatch(customersList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);